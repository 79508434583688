import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import {
  addItem,
  addOneQty,
  addQty,
  removeItem,
  removeQty,
} from '../../redux/cart';
import styles from './counter.module.css';

const Counter = ({ product }) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state);
  const found = cart.find((cartitem) => cartitem.id === product.id);
  // --------------------------------------->
  //Add product in the cart..
  const addToCart = (evt) => {
    evt.stopPropagation();
    //check if product has same currency
    if (cart.length){
      if (cart[0].currency !== product.currency) {
           toast.error('Cart must not contain items with different currencies. Please checkout items separately.', {
             duration: 2500,
           });
           return;
      }
    }

    //check if product already in a cart.. in that case increment quantity
    const found = cart.find((cartitem) => cartitem.id === product.id);

    //Verify stock.. and add if stock available
    if (found === undefined && product.qty >= 1) {
      dispatch(addItem(product));
    } else if (found && found.orderedQty < product.qty) {
      dispatch(addOneQty(product.id));
    } else {
      // setFeedback('no more stock'');
      toast.error('You have reached the stock limit for this article.', {
        duration: 2500,
      });
    }
  };

  // --------------------------------------->
  //Remove an itme for the cart..
  const removeFromCart = (evt) => {
    evt.stopPropagation();
    //Find index of product to be removed...
    const index = cart.findIndex((cartitem) => cartitem.id === product.id);
    //product is found.. so let's proceed...
    if (index !== -1) {
      if (cart[index].orderedQty <= 1) {
        //last item in the cart..so remove the item..
        dispatch(removeItem(index));
      } else {
        //More than one articles ..remove one from quantity.
        dispatch(removeQty(index));
      }
    }
  };

  // --------------------------------------->
  //Add product in the cart..
  const addQtyToCart = (evt) => {
    evt.stopPropagation();
    const qty = parseInt(evt.target.value);

    //Verify stock.. and add if stock available
    if (qty === 0) {
      const index = cart.findIndex((cartitem) => cartitem.id === product.id);
        dispatch(removeItem(index)); 
    } else if (qty <= product.qty) {
      dispatch(addQty({ id: product.id, qty }));
    } else {
      // setFeedback('no more stock'');
      toast.error('You have reached the stock limit for this article.', {
        duration: 2500,
      });
    }
  };

  //Product qty in the cart..
  const cartQty = () => {
    const found = cart.find((cartitem) => cartitem.id === product.id);
    if (found) {
      return found.orderedQty;
    }
    return 0;
  };

  return cartQty() ? (
    <div className={styles.counter} key={product.id}>
      <Toaster id='alert' position='top-center' reverseOrder={false} />
      <btn onClick={(evt) => removeFromCart(evt)}>-</btn>
      <input
        className={styles.cartqty}
        type='number'
        name='qty'
        id={styles.cartQty}
        value={found?.orderedQty}
        onChange={addQtyToCart}
      />
      <btn onClick={(evt) => addToCart(evt)}>+</btn>
    </div>
  ) : (
    <div
      className={`${styles.counter} ${styles.smcounter}`}
      key={`plus${product.id}`}
    >
      <Toaster
        className={styles.alert}
        position='top-center'
        reverseOrder={false}
      />
      <btn onClick={(evt) => addToCart(evt)}>
        <FontAwesomeIcon className={styles.carticon} icon='cart-plus' />
      </btn>
    </div>
  );
};

export default Counter;
