import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SEO from '../components/seo';
import { useSelector, useDispatch } from 'react-redux';
import { EditListingForm } from '../components/edit_listing_form';
import Banner from '../components/banner';
import axios from 'axios';
import isLoggedIn from '../utils/isLoggedIn';

const EditListing = () => {
  //set initial states, comics, characters and query filters
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/api/myproduct/${id}`;
  const dispatch = useDispatch()

  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const { user } = useSelector((state) => state.user);
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  // UseEffects, check if there's a user and token ---------------------------------------------------\\

  useEffect(() => {
    isLoggedIn(navigate, dispatch, '/user/login');
    let active = true;
    (async () => {
    const productData = await axios(url, {
      headers: { authorization: `Bearer ${token}` },
    });
    if (active) {
      setProduct(productData.data);
      setLoading(false);
    }
  })();
        return () => {
          active = false;
          setProduct(null);
        };

  }, [id]);




  // Login form submit handler ---------------------------------------------------\\

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Edit your listing`}
        page='Edit your listing'
        description={`BADGERS:  Edit your listing`}
        name='BADGERS'
        type='Product'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <SEO
        title={`BADGERS: ${product.title}`}
        page={product.title}
        description={`BADGERS:  ${product.categorie}, ${product.title}`}
        name='BADGERS'
        type='Product'
      />
      <Banner />
      <EditListingForm user={user} token={token} product={product} />
    </>
  );
};

export default EditListing;
