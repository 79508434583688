import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeItem } from '../../redux/cart';
import { useDispatch, useSelector } from 'react-redux';
import Counter from './counter';
import styles from './item.module.css';


const Item = ({ product }) => {
  const dispatch = useDispatch();
  const {cart} = useSelector(state => state);

  //Remove an itme for the cart..
  const removeFromCart = (evt) => {
    evt.stopPropagation();
    //Find index of product to be removed...
    const index = cart.findIndex((cartitem) => cartitem.id === product.id);
    //product is found.. so let's proceed...
    if (index !== -1) {
        //last item in the cart..so remove the item..
        dispatch(removeItem(index));
    }
  };

  return (
    <div className={styles.productcard} key={product?.id.toString()}>
      <div className={styles.productimg}>
        <img
          className={styles.productimg}
          src={product.images[0]?.lienImage}
          alt=''
        />
      </div>

      <div className={styles.productdetails}>
        <div className={styles.productdetailspan}>
          <p
            className={`${styles.producttitle} text-wrap`}
            key={product.id}
            dangerouslySetInnerHTML={{ __html: product.title }}
          />

          <p className={styles.price}>
            {product.currency === 'usd' ? '$ ' : '€ '}
            {Number(product.price).toLocaleString('en')}
          </p>
          <p className={styles.subheads}>Brand: {product.brand}</p>
          <p className={styles.subheads}>Type: {product.type}</p>
        </div>
        <div className={styles.productlocspan}>
          <Counter product={product} />
          <button
            className={`${styles.del} ${styles.btn}`}
            onClick={removeFromCart}
          >
            <FontAwesomeIcon icon='trash' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Item;
