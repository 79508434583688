import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import SEO from '../components/seo';
import { useSelector, useDispatch } from 'react-redux';
import { ListingForm } from '../components/listingform';
import Banner from '../components/banner';
import isLoggedIn from '../utils/isLoggedIn';

const Listing = () => {
  //set initial states, comics, characters and query filters

  const {user} = useSelector((state) => state.user);
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // UseEffects, check if there's a user and token ---------------------------------------------------\\

  useEffect(() => {
    isLoggedIn(navigate, dispatch, '/listing/add'); 
  }, []);

  // Login form submit handler ---------------------------------------------------\\

  

  return (
    <>
      <SEO
        title={`BADGERS: List and sell your product`}
        page='add product listing'
        description={`BADGERS:  add product listing`}
        name='BADGERS'
        type='Product'
      />
      <Banner />
      <ListingForm user={user} token={token} />
    </>
  );
};

export default Listing;
