import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cats from '../data/categories.json';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, clearFilters, setDisplayCount, setPage, nextPage, prevPage } from '../redux/filters';
import { truncate } from "../utils/utils";
import styles from './filter.module.css';


// Filter Component -------------------------------------------------

const Filter = ({ count, displayMob }) => {
  const categories = Object.keys(cats);
  const filters = useSelector((state) => state.filters);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  //Handle dynamic filter change
  const handleFilter = (evt, filterProperty) => {
    const newFilters = { ...filters };
    newFilters[filterProperty] = evt.target.value;
    dispatch(setFilters(newFilters));
  };

  //Handle page number drop-down, Dynmaic as per number of query results
  const pageSelector = (filters) => {
    let options = [];
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    for (let i = 1; i <= pages; i++) {
      options.push(
        <option  value={`${i}`} key={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  //Handle next page;
  const goToNext = () => {
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    if (filters.page < pages) {
      dispatch(nextPage());
    }
  };

  //Handle previous page;
  const goToPrev = () => {
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    if (filters.page > 1) {
      dispatch(prevPage());
    }
  };

  return (
    <div className={styles.filter} id=''>
      <div className={styles.searchpan}>
          <select
            onChange={(evt) => handleFilter(evt, 'categorie')}
            name='searchField'
            id='searchfield'
            className={styles.searchfield}
            value={filters.categorie}
          >
            <option value=''>CATEGORIES</option>
            {categories.map((d, idx) => (
              <option value={d} key={idx}>
                {truncate(d, 25)}
              </option>
            ))}
          </select>
    
          <input
            className={styles.searchbar}
            onChange={(evt) => handleFilter(evt, 'searchStr')}
            value={filters.searchStr}
            type='text'
            name='title'
            id='title'
            placeholder='search listings ...'
          />
    
      </div>

      {/* Number of results per page */}
      <div className={styles.pages}>
        <FontAwesomeIcon className={styles.filtericon} icon='th' />
        <select
          name='results-per-page'
          id='results-per-page'
          onChange={(evt) => dispatch(setDisplayCount(evt.target.value))}
        >
          <option value='20'>20</option>
          <option value='40'>40</option>
          <option value='60'>60</option>
          <option value='80'>80</option>
          <option value='100'>100</option>
        </select>
        <FontAwesomeIcon className={styles.filtericon} icon='forward' />
        <select
          value={filters.page.toString()}
          name='page'
          id='page'
          onChange={(evt) => dispatch(setPage(evt.target.value))}
        >
          {pageSelector(filters)}
        </select>
        <FontAwesomeIcon
          onClick={goToPrev}
          className={styles.filtericon}
          icon='step-backward'
        />
        <FontAwesomeIcon
          onClick={goToNext}
          className={styles.filtericon}
          icon='step-forward'
        />
      </div>
    </div>
  );
};

export default Filter;
