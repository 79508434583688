import { createSlice } from '@reduxjs/toolkit';

const initialCart = localStorage.localCart
  ? JSON.parse(localStorage.localCart)
  : [];

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCart,
  reducers: {
    addItem: (state, action) => {
      //add a new product to cart array
      const {id, title, brand, condition, type, price, qty, currency, images} = action.payload;
      const product = {
        id,
        title,
        brand,
        condition,
        type,
        price,
        currency,
        images,
        qty,
        orderedQty: 1,
      };
      state.push(product);
      localStorage.localCart = JSON.stringify(state);
    },

    addOneQty: (state, action) => {
      // Product already in cart..increment quantity
      const found = state.find((cartitem) => cartitem.id === action.payload);
      found.orderedQty++;
      localStorage.localCart = JSON.stringify(state);
    },

    addQty: (state, action) => {
      // Product already in cart..increment quantity
      const found = state.find((cartitem) => cartitem.id === action.payload.id);
      found.orderedQty = action.payload.qty;
      localStorage.localCart = JSON.stringify(state);
    },

    removeItem: (state, action) => {
      // remove an item for the cart array
      state.splice(action.payload, 1);
      localStorage.localCart = JSON.stringify(state);
    },

    removeQty: (state, action) => {
      //decrease quantity for a specific product
      state[action.payload].orderedQty--;
      localStorage.localCart = JSON.stringify(state);
    },
    resetCart: (state, action) => {
      // reset cart
      localStorage.removeItem('localCart');
      state.length = 0;
    },
  },
});

export default cartSlice.reducer;

export const { addItem, addOneQty, addQty, removeItem, removeQty, resetCart } =
  cartSlice.actions;
