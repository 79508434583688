import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './industries.module.css';
import Banner from '../components/banner';

const Industries = () => {

  return (
    <>
      <Banner />
      <SEO
        title={`BADGERS: Revolutionizing Surplus Materials Management`}
        page='industries'
        description={`Explore BADGERS' innovative platform for surplus materials in energy and industrial sectors. Connect, minimize waste, and maximize cost savings with cutting-edge technology.`}
        keywords={`Inventory marketplace`}
        robots={`index, follow`}
        type='website'
        creator='Badgers SAS'
        link={`https://www.bdgrs.com/industries`}
        image='https://www.bdgrs.com/img/services/oilandgas.png'
      />
      <div className='container'>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Industries</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Industries <FontAwesomeIcon icon='people-arrows' />
          </h4>
        </div>
        <div className='d-flex justify-content-center'>
          <div class={styles.timeline}>
            <div class={styles.outer}>
              <div class={styles.card}>
                <div class={styles.info}>
                  <div className='d-flex justify-content-center mb-5'>
                    <img
                      src='/img/services/oilandgas.png'
                      alt='about_us'
                      className={styles.img}
                    />
                  </div>
                  <h3 class={styles.title}>OIL & GAS</h3>
                  <p className={styles.desc}>
                    We focus on downhole and surface equipment. This includes
                    float shoes, screens, packers, tubing, casing, wellheads,
                    completion accessories, pipelines, mud/cement/frac pumps,
                    rigs, spill prevention, etc. Our ecosystem consists of
                    operators, service companies, manufacturers and consultants
                    that will assist in designing and installing for your
                    project, enhancing your resource utilization.{' '}
                  </p>
                </div>
              </div>
              <div class={styles.card}>
                <div class={styles.info}>
                  <div className='d-flex justify-content-center mb-5'>
                    <img
                      src='/img/services/mining.png'
                      alt='about_us'
                      className={styles.img}
                    />
                  </div>
                  <h3 class={styles.title}>MINING</h3>
                  <p className={styles.desc}>
                    We deal with spare parts for the extraction and the plant
                    side. This includes kits, belts, castings, ball mills,
                    drilling, blasting, etc for underground, open-pit, and
                    in-situ leaching. We will segment, refurbish, and perform a
                    price survey before listing your inventory. You will
                    purchase quality equipment and experience improved project
                    efficiency..{' '}
                  </p>
                </div>
              </div>
              <div class={styles.card}>
                <div class={styles.info}>
                  <div className='d-flex justify-content-center mb-5'>
                    <img
                      src='/img/services/equipment.png'
                      alt='about_us'
                      className={styles.img}
                    />
                  </div>
                  <h3 class={styles.title}>HEAVY EQUIPMENT</h3>
                  <p className={styles.desc}>
                    We intervene in machinery and spare parts that could be used
                    in any industry. This includes bulldozers, excavators,
                    tractors, trucks, loaders, etc. We will assist you to sell
                    and buy at the best price. On our marketplace, you have the
                    choice between multiple brands including Caterpillar,
                    Komatsu, and Atlas Copco.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Industries;
