import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import SEO from '../components/seo';
import Item from '../components/checkout/item';
import styles from './cart.module.css';
import FooterProfile from '../components/footer_profile';
import { useSelector } from 'react-redux';

const Cart = () => {
  // Initial state and variables ---------------------------------------------------\\
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state);

  //Calculate total cart value
  const cartTotal = (cart) => {
    if (cart.length) {
      return cart.reduce((cur, acc) => {
        return cur + acc.price * acc.orderedQty;
      }, 0);
    }
    return 0;
  };

  return (
    <>
      <SEO
        title={`BADGERS: Cart`}
        page='cart'
        description={`BADGERS: Cart`}
        name='BADGERS'
        type='cart'
      />
      <div className='container'>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Cart</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Shopping Cart <FontAwesomeIcon icon='cart-plus' />
          </h4>
        </div>
        {cart.length ? (
          <div className={styles.cart}>
            <div className={styles.carthead}>
              <div>{`${cart.length} ${(cart.length === 1)? 'Article' : 'Articles'}`}</div>
            </div>
            <div>
              {cart?.map((product, index) => (
                <Item product={product} key={`${index}${product.id}`} />
              ))}
            </div>
            <div className={styles.cartvalue}>
              <div>Sub-Total</div>
              <div>
                {cartTotal(cart)?.toFixed(2)}{' '}
                {cart[0]?.currency === 'eur' ? '€' : 'US$'}
              </div>
            </div>
            <div className='d-grid gap-2 my-3'>
              <button
                className={`btn ${styles.btnsubmit}`}
                onClick={() => navigate('/delivery')}
              >
                {`Delivery `}
                <FontAwesomeIcon icon='truck-moving' />
              </button>
            </div>
          </div>
        ) : (
          'Your shopping cart is empty.'
        )}
      </div>
      <FooterProfile />
    </>
  );
};

export default Cart;
