import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './info.module.css';
import Banner from '../components/banner';

const Terms = () => {
  // Initial state and variables ---------------------------------------------------\\

  return (
    <>
      <Banner />
      <SEO
        title={`BADGERS: Terms and Conditions`}
        page='terms and conditions'
        description={`BADGERS: terms and conditions for platform users`}
        name='BADGERS'
        type='Policy'
      />

      <div className={styles.pan}>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Terms & Conditions</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Terms & Conditions <FontAwesomeIcon icon='balance-scale' />
          </h4>
        </div>
        <div className='row g-3' action=''>
          <p>Thank you for your interest in Badgers SAS marketplace.</p>
          <p>
            These website terms of use, which incorporate the Acquisition
            Mandate (as defined below) apply to your use of this website and any
            and all other websites operated by Badgers SAS (each a "Site").
            Please note that these Terms apply, regardless of the means of
            delivery of any Site to you.
          </p>
          <p>
            By using any Site and or listing you *equipment or *product on
            Badgers SAS marketplace, you indicate that you have read and accept
            these Terms, including the applicable Acquisition Mandate and agree
            to abide by and be bound by all such Terms (as modified from time to
            time). If you do not accept all applicable Terms, please refrain
            from using any Site.
          </p>
          <p>
            Please note that we may change these Terms from time to time without
            notice so you should review them each time that you visit any
            Badgers SAS Site.
          </p>
          <h5 className='h5'>Acquisition Mandate</h5>
          <p>
            This agreement (the <strong>“Agreement”</strong>) is entered into on
            the date hereof,
          </p>
          <p>
            <strong>AND:</strong>
          </p>
          <ol>
            <li>
              (1) <strong className={styles.strong}>BADGERS SAS</strong>, a
              simplified joint stock company (société par actions simplifiée)
              incorporated under the laws of France with share capital of 50 567
              €, having its registered office located 12, avenue des Près –
              78180 Montigny-le-Bretonneux, France, registered with the trade
              and companies register of Paris under number 899 296 339 (
              <strong>"BADGERS"</strong>);
            </li>
            <p className='my-3'>
              <strong>BETWEEN:</strong>
            </p>
            <li>
              (2) [●1] , a [●2] incorporated under the laws of [●3] with share
              capital of [●4], having its registered office located [●5],
              registered with the trade and companies register of [●6] under
              number [●7] (the “Company”).
            </li>
          </ol>
          <p>
            BADGERS and the Company are hereafter referred to, collectively, as
            the <strong>“Parties”</strong>, and individually, as a{' '}
            <strong>“Party”</strong>.
          </p>
          <p>
            <strong>WHEREAS:</strong>
          </p>

          <ol>
            <li className='my-2'>
              <strong>(A)</strong> BADGERS operates an e-commerce platform that
              helps the oil & gas industry to revitalize surplus inventories in
              a circular economy. In the context of its activities, BADGERS may
              be in contact with companies owning some surplus industrial
              equipment that they would like to divest.
            </li>
            <li className='my-2'>
              <strong>(B)</strong> The Company is wishing to acquire some
              industrial equipment, and is interested in the acquisition
              opportunities that BADGERS may introduce.
            </li>
            <li className='my-2'>
              <strong>(C)</strong> In this respect, the Parties have decided to
              enter into this Agreement so as to set out their respective rights
              and obligations in connection with the services and assistance to
              be provided to the Company by BADGERS.
            </li>
          </ol>
          <p className='h6'>
            <strong>NOW, THEREFORE, THE PARTIES HAVE AGREED AS FOLLOWS:</strong>
          </p>

          <ol class='list-group list-group-numbered'>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Purpose</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  The purpose of this Agreement is to define the conditions
                  under which the Company grants an acquisition mandate to
                  BADGERS, in order to appoint BADGERS as its attorney in charge
                  of acquiring some industrial equipment (the{' '}
                  <strong>“Equipment”</strong>).
                </li>
                <li class='my-2 list-group-item border-0'>
                  The features of the Equipment that the Company is wishing to
                  acquire are set out in <strong>Schedule 1</strong> hereto.
                </li>
                <li class='my-2 list-group-item border-0'>
                  The Parties acknowledge and agree that the list of Equipment
                  may be supplemented by the Company, subject to BADGERS prior
                  approval.
                </li>
              </ol>
            </li>

            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Acquisition Mandate</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  The Company hereby irrevocably appoints BADGERS, as per the
                  terms of this Agreement, as its representative in charge of
                  finding the Equipment that the Company wishes to acquire, and
                  to manage any relating legal and administrative formalities in
                  the name and on behalf of the Company.
                </li>
                <li class='my-2 list-group-item border-0'>
                  The price of the Equipment shall be jointly determined by the
                  Parties, it being specified that such price cannot be, unless
                  prior approval of the Company, higher than the price set out
                  in <strong>Schedule 1</strong>.
                </li>
                <li class='my-2 list-group-item border-0'>
                  BADGERS accepts this mandate, and undertakes to carry out any
                  requisite action in order to acquire the Equipment in the name
                  and on behalf of the Company.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Remuneration - Costs</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  If the acquisition of any piece of Equipment is completed,
                  BADGERS shall receive a fee equal to thirty-five percent (35%)
                  of the price of the given piece of Equipment (price, without
                  deduction of any costs or taxes, VAT excluded – the{' '}
                  <strong>“Fee”</strong>).
                </li>
                <li class='my-2 list-group-item border-0'>
                  The payment of the Fee shall be made by the Company, it being
                  specified that the Company may contractually decide in the
                  documentation relating to the acquisition of the Equipment
                  that such Fee shall be paid by the seller of the Equipment
                  directly to BADGERS. In such a case, the Company shall remain
                  jointly liable of the payment of the Fee by the third party
                  seller.
                </li>
                <li class='my-2 list-group-item border-0'>
                  The payment of the Fee shall be made by the Company, it being
                  specified that the Company may contractually decide in the
                  documentation relating to the acquisition of the Equipment
                  that such Fee shall be paid by the seller of the Equipment
                  directly to BADGERS. In such a case, the Company shall remain
                  jointly liable of the payment of the Fee by the third party
                  seller.
                </li>
                <li class='my-2 list-group-item border-0'>
                  Any costs in relation to the acquisition of the Equipment
                  shall be borne by the Company. Any costs and disbursements
                  that may have been paid by BADGERS in connection with the
                  acquisition of the Equipment shall be reimbursed by the
                  Company to BADGERS at the latest on the date of the transfer
                  of ownership of the Equipment from the third-party seller to
                  the Company.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Payments</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  <strong className='h6'>Invoice Process:</strong>
                  <ol class='list-group list-group-numbered'>
                    <li class='my-2 list-group-item border-0'>
                      Payments under this Agreement shall be made by the Company
                      by wire transfer to the bank account of BADGERS, the
                      details of which will be provided to the Company by
                      BADGERS.
                    </li>
                    <li class='my-2 list-group-item border-0'>
                      BADGERS shall provide the Company with the corresponding
                      invoice in relation to the payment of the Fee.
                    </li>
                  </ol>
                </li>
                <li class='my-2 list-group-item border-0'>
                  <strong className='h6'>Currency Account:</strong>
                  <ol class='list-group list-group-numbered'>
                    <li class='my-2 list-group-item border-0'>
                      Unless otherwise notified by BADGERS, US Dollars shall be
                      the currency of account and of payment of all amounts due
                      under and pursuant to this Agreement.
                    </li>
                  </ol>
                </li>
                <li class='my-2 list-group-item border-0'>
                  <strong className='h6'>Tax Gross-up</strong>
                  <ol class='list-group list-group-numbered'>
                    <li class='my-2 list-group-item border-0'>
                      When required by law, VAT will be invoiced by BADGERS in
                      addition to the amount of the Fee.
                    </li>
                    <li class='my-2 list-group-item border-0'>
                      The Company shall make all payments to be made by it under
                      this Agreement without any tax deduction unless a tax
                      deduction is required by law. In such a case, the payment
                      shall be grossed up so that BADGERS effectively receives
                      the whole amount of the Fee as per the terms of this
                      Agreement.
                    </li>
                    <li class='my-2 list-group-item border-0'>
                      The Company shall promptly upon becoming aware that it
                      must make a tax deduction notify BADGERS accordingly.
                      Similarly, BADGERS shall notify the Company upon becoming
                      so aware in respect of a payment payable to it.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Exclusivity</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  The acquisition mandate is exclusively granted to BADGERS in
                  relation to the Equipment, for a period of twelve months as
                  from the date hereof.
                </li>
                <li class='my-2 list-group-item border-0'>
                  As a consequence, the Company undertakes throughout this
                  period to refrain from soliciting any third party in relation
                  to the acquisition of the Equipment, it being specified that
                  such undertaking shall in particular prevent the Company from
                  granting to any third party a mandate similar to this mandate
                  in relation to acquisition of the Equipment, and from having
                  any direct contacts with any potential seller (if a given
                  third party contacts the Company in relation to the
                  acquisition of the Equipment, the Company shall inform BADGERS
                  and put such third party in contact with BADGERS).
                </li>
                <li class='my-2 list-group-item border-0'>
                  If the Equipment is acquired by the Company during the
                  exclusivity period set out above, including without any action
                  from BADGERS, the Fee will in any case be due to BADGERS.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Duration</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  This Agreement is entered into for a period of twelve months
                  as from the date hereof, and may be tacitly renewed for one or
                  more additional twelve months periods unless prior written
                  notice from either Party sent to the other Party at least one
                  month before the expiry of a given term.
                </li>
                <li class='my-2 list-group-item border-0'>
                  This Agreement shall be deemed terminated in the event of the
                  acquisition of all the Equipment.
                </li>
                <li class='my-2 list-group-item border-0'>
                  The acquisition mandate contained in this Agreement is
                  irrevocable, the Company acknowledges that it cannot terminate
                  this Agreement before the expiry of its initial term.{' '}
                </li>
                <li class='my-2 list-group-item border-0'>
                  In the event of any early termination of this Agreement by the
                  Company, the Company shall indemnify BADGERS and pay a
                  lump-sum amount of 1000 US$ (one thousand United State
                  dollars). This indemnity shall be paid within ten 30 days as
                  from termination of this Agreement.{' '}
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Late Interests</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  If the Company fails to pay any amount payable by him under
                  this Agreement on its due date, interest shall accrue to the
                  fullest extent permitted by law on the overdue amount from the
                  due date up to the date of actual payment at a rate equal to
                  twelve percent (12 %) per year (based on years of 365 days).
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Badgers' Undertaking</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  BADGERS undertakes to use its best efforts to render the
                  services under this Agreement in order to arrange for the
                  acquisition of the Equipment as per the terms of this
                  Agreement.
                </li>
                <li class='my-2 list-group-item border-0'>
                  BADGERS shall comply with all laws, rules and regulations, of
                  any kind, applicable to the execution of this Agreement.
                </li>
                <li class='my-2 list-group-item border-0'>
                  BADGERS shall keep the Company informed on a regular basis of
                  any progress in the acquisition of the Equipment.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Company's Undertaking</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  The company undertakes to:
                  <ol className='list-group list-group-numbered'>
                    <li className='list-group-item border-0'>
                      Sign any documentation that may be required in the context
                      of the acquisition of the Equipment.
                    </li>
                    <li className='list-group-item border-0'>
                      Pay the Fee to BADGERS, and more generally any sums that
                      may be owed to BADGERS under this Agreement.
                    </li>
                    <li className='list-group-item border-0'>
                      Refrain, for a period of two (2) years as from the
                      termination of this Agreement, for any reason whatsoever,
                      from acquiring equipment similar to the Equipment to
                      sellers that would have been in contact with BADGERS for
                      the purpose of the acquisition of the Equipment.
                    </li>
                    <li className='list-group-item border-0'>
                      Collaborate in good faith with BADGERS in order to support
                      and facilitate the acquisition of the Equipment under this
                      Agreement.
                    </li>
                    <li className='list-group-item border-0'>
                      Provide BADGERS with all information which may be required
                      by BADGERS, acting reasonably, to enable it to deliver the
                      services in accordance with the terms of this Agreement.
                    </li>
                  </ol>
                </li>
                <li class='my-2 list-group-item border-0'>
                  More generally, the Company undertakes to do whatever is
                  commercially reasonable in order to put BADGERS into the best
                  conditions possible to complete the acquisition of the
                  Equipment successfully and quickly.
                </li>
                <li class='my-2 list-group-item border-0'></li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Obligations in Terms of Taxes</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  Each Party is solely and exclusively responsible for the
                  payment of (if any) all income tax liabilities, social
                  security contributions, employee insurance contributions or
                  equivalent arising as result of the arrangements set out in
                  this Agreement, and undertakes to provide any supporting
                  documents to demonstrate this upon the other Party’s request.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Confidentiality</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  Each Party undertakes to keep as strictly confidential, and
                  consequently shall not, without the prior written agreement of
                  the other Party, disclose, publish or communicate to anyone
                  whomsoever, in any form whatsoever, any documents,
                  information, contracts, results, data, secrets, know-how,
                  processes or any other information of any kind whatsoever
                  relating, in particular, to the business of the other Party
                  (hereinafter the <strong>“Confidential information”</strong>).
                </li>
                <li class='my-2 list-group-item border-0'>
                  Each Party undertakes not to use, or attempt to use, the
                  Confidential Information in any way which may, directly or
                  indirectly, damage, prejudice or cause losses (including
                  relating reasonable expenses, proceedings, costs and expenses)
                  of any kind to the other Party.
                </li>
                <li class='my-2 list-group-item border-0'>
                  All the provisions relating to confidentiality shall be valid
                  throughout the term of this Agreement and shall remain in
                  force after its termination, for whatever reason, for a
                  further term of two (2) years.{' '}
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Personal Data</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  The Parties acknowledge that this Agreement and the services
                  rendered under this Agreement will not incur any personal data
                  processing nor personal data transfer outside of France.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Insurance</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  Each Party undertakes to take out any necessary insurance
                  policies, of whatever kind, which may be necessary to complete
                  the Agreement.
                </li>
              </ol>
            </li>
            <li class='my-2 list-group-item bg-light border-0'>
              <strong className='h6'>Miscellaneous Provisions</strong>
              <ol class='list-group list-group-numbered'>
                <li class='my-2 list-group-item border-0'>
                  <span className='h6'>Entire Agreement</span>
                  <p className='mt-2'>
                    This Agreement represents the entire agreement and
                    understanding of the Parties with reference to the
                    transaction and services set forth herein and no
                    representations or warranties have been made in connection
                    with this Agreement other than those expressly set forth
                    herein. This Agreement supersedes all prior negotiations,
                    discussions, correspondence, communications, understandings,
                    and agreements between the Parties relating to the subject
                    matter of this Agreement and all prior drafts of this
                    Agreement.
                  </p>
                </li>
                <li class='my-2 list-group-item border-0'>
                  <span className='h6'>Severability</span>
                  <p className='mt-2'>
                    This Agreement shall be deemed severable, and the invalidity
                    or unenforceability of any term or provision hereof shall
                    not affect the validity or enforceability of this Agreement
                    or of any other term or provision hereof. Furthermore, in
                    lieu of any such invalid or unenforceable term or provision,
                    the parties hereto intend that there shall be added as a
                    part of this Agreement a provision as similar in terms to
                    such invalid or unenforceable provision as may be possible
                    and be valid and enforceable.
                  </p>
                </li>
                <li class='my-2 list-group-item border-0'>
                  <span className='h6'>Waivers and Amendments</span>
                  <p className='mt-2'>
                    No terms of this Agreement may be altered, modified,
                    amended, supplemented or terminated except by an instrument
                    in writing duly signed by all the Parties. Any waiver of any
                    term or condition of this Agreement must be in writing
                    signed by the Party sought to be charged with such waiver
                    referring specifically to the term or condition to be
                    waived, and no such waiver shall be deemed to constitute the
                    waiver of any other breach of the same or of any other term
                    or condition of this Agreement.
                  </p>
                </li>
                <li class='my-2 list-group-item border-0'>
                  <span className='h6'>Applicable Law - Jurisdiction</span>
                  <p className='mt-2'>
                    This Agreement shall be governed by and construed in
                    accordance with French laws. If a dispute arises between the
                    Parties as to the execution, interpretation or validity of
                    the Agreement, such dispute shall be referred by either
                    Party to the exclusive jurisdiction of the courts located
                    within the jurisdiction of the court of appeal of Paris.
                  </p>
                </li>
              </ol>
            </li>
          </ol>

          <hr />
          <div>
            <p>[●1] - company name</p>
            <p>[●2] - company's legal form</p>
            <p>[●3] - country, incorporated in</p>
            <p>[●4] - company capital</p>
            <p>[●5] - company's legal address</p>
            <p>[●6] - company register</p>
            <p>[●7] - company's registration number</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
