import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from '../redux/user';

const Navbar = ({ displayMob, setDisplayMob }) => {
  // Initial state and variables ---------------------------------------------------\\

  const url = `${process.env.REACT_APP_API}/aut/`;
  const dispatch = useDispatch();
  // const token = sessionStorage.getItem('token');
  const { user, token } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state);
  const navigate = useNavigate();

  //check if user is logged-in
  const handleFavourites = (evt) => {
    navigate('/', { state: { field: evt.target.value } });
  };

  // Logout handler ---------------------------------------------------\\

  const onLogOut = () => {
    dispatch(logoutSuccess());
    navigate('/user/login');
  };

  return (
    <div className='navbar'>
      <div className='nav'>
        <div className=''>
          <Link to='/'>
            <img className='logo' src='/img/logo.png' alt='logo' />
          </Link>
          <FontAwesomeIcon
            onClick={() => setDisplayMob(!displayMob)}
            className='burger'
            icon='bars'
          />
        </div>

        <ul className={`nav-items ${displayMob ? undefined : 'hide'}`}>
          <li>
            <Link to='/'>HOME</Link>
          </li>
          <li>
            <Link to='/about'> ABOUT</Link>
          </li>
          <li>
            <Link to='/blog'> BLOG</Link>
          </li>
          <li>
            <Link to='/services'> SERVICES</Link>
          </li>
          <li>
            {token && (
              <Link id='link' className='favicontext' to={'/favourites'}>
                FAVOURITES
              </Link>
            )}
          </li>
          <li>
            <Link id='btnsell' to='/listing/add'>
              SELL{'  '}
              <FontAwesomeIcon className='' icon='bullhorn' />
            </Link>
          </li>
        </ul>

        <div className={`sign-in`}>
          {!token ? (
            <div
              className='login-ins nav-items'
              id={token ? 'signin-hide' : 'signin'}
            >
              <Link to='/user/login'>
                <span>SIGN IN | JOIN </span>
                <FontAwesomeIcon className='signin-icon' icon='sign-in-alt' />
              </Link>
            </div>
          ) : (
            <div className='nav-item dropdown login-ins'>
              <a
                className='nav-link dropdown-toggle'
                href='#'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <FontAwesomeIcon className='username' icon='smile-wink' />
                {` Hello ${user?.userName?.toUpperCase()}`}
              </a>
              <ul className='dropdown-menu'>
                <li>
                  <a className='dropdown-item' href='/user/update'>
                    Profile
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/user/settings'>
                    Settings
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/user/orders'>
                    My Orders
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/user/listings'>
                    My Listings
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/favourites'>
                    Favourite Listings
                  </a>
                </li>
                <li>
                  <hr className='dropdown-divider' />
                </li>
                <li>
                  <a
                    className='dropdown-item'
                    href='/'
                    onClick={() => onLogOut()}
                  >
                    Logout
                    <FontAwesomeIcon
                      className='logout-icon'
                      icon='sign-out-alt'
                    />
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className=''>
            {/* // Cart -------------->> */}
            <button
              type='button'
              className='px-1 btn btn-sm btn-success position-relative'
              onClick={() => navigate('/cart')}
            >
              <FontAwesomeIcon className='carticon' icon='shopping-cart' />
              { cart.length ? <span class='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                  {cart.length}
                  <span class='visually-hidden'>cart items</span>
                </span> : undefined
              }
            </button>
            {/* // Cart <-------------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
