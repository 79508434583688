import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import SEO from '../components/seo';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import countries from '../data/countries.json';
import styles from './signup.module.css';
import useProfile from '../redux/hooks/useProfile';

const ProfileUpdate = () => {
  // Initial state and variables ---------------------------------------------------\\

  const [feedback, setFeedback] = useState('');
  const [apiErrs, setApierrs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [locked, setLock] = useState(true);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const token = sessionStorage.getItem('token');
  const { loading, profile } = useProfile(token, user);

  const url = `${process.env.REACT_APP_API}/api/user`;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  // UseEffect to populate from fields. ---------------------------------------------------\\
  useEffect(() => {
    reset(profile);
  }, [loading]);


  // Handle signup ---------------------------------------------------\\
  const handleUpdate = async (data) => {
    const { prenom, nom, email, contact, entreprise, fonction, pays, ville } =
      data;
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');

    try {
      const newUser = {
        prenom,
        nom,
        email,
        contact,
        entreprise,
        fonction,
        pays,
        ville,
      };
      setSpinner(true);
      const response = await axios.put(`${url}/${user.userId}`, newUser, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success('Your account details were successfully updated.', {
          duration: 4000,
        });
        setSpinner(false);
        setApierrs([])
        setLock(true);
      } else {
        setFeedback(
          `Error Code: ${response.status}. \n OOOOPS.. ${response.msg}`
        );
      }
    } catch (error) {
      setApierrs(error.response.data.errors);
      setSpinner(false);
    }
  };

  // Map API Errors ---------------------------------------------------\\
  const mapErrs = (obj) => {
    const keys = Object.keys(obj);
    return (
      <p className='help-block text-danger my-2'>
        {`${keys[0]}: `}
        {obj[keys[0]]}
      </p>
    );
  };

  // Rendered page ---------------------------------------------------\\
  return (
    <div className={styles.pan}>
      <SEO
        title={`BADGERS: Update your profile`}
        page='edit and update member profile'
        description={`BADGERS: edit and update member profile`}
        name='BADGERS'
        type='Users'
      />
      <Toaster position='top-center' reverseOrder={false} />
      <form onSubmit={handleSubmit(handleUpdate)} className='row g-3' action=''>
        {/* ----------------------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>User Profile</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h5 className='h5'>
            Your Profile <FontAwesomeIcon icon='user' />
          </h5>
        </div>
        <div className={`${styles.card} row`}>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.prenom}
              readOnly={locked}
              className='form-control'
              type='text'
              name='prenom'
              id='prenom'
              {...register('prenom', {
                required: 'Please enter your first name.',
                maxLength: 30,
                minLength: 3,
              })}
            />
            {errors?.prenom && (
              <p className='help-block text-danger my-2'>
                {errors.prenom?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.nom}
              readOnly={locked}
              className='form-control'
              type='text'
              name='nom'
              id='nom'
              placeholder='Family Name'
              {...register('nom', {
                required: 'Please enter your surname.',
                maxLength: 30,
                minLength: 3,
              })}
            />
            {errors?.nom && (
              <p className='help-block text-danger my-2'>
                {errors.nom?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.email}
              readOnly={locked}
              className='form-control'
              type='email'
              name='email'
              id='email'
              placeholder='Email Address'
              {...register('email', {
                required: 'Please enter a valid email address.',
              })}
            />
            {errors?.email && (
              <p className='help-block text-danger my-2'>
                {errors.email?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.contact}
              readOnly={locked}
              className='form-control'
              type='text'
              name='contact'
              id='contact'
              placeholder='Contact Number'
              {...register('contact', {
                required: 'Please enter a valid telephone number.',
              })}
            />
            {errors?.contact && (
              <p className='help-block text-danger my-2'>
                {errors.contact?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.entreprise}
              readOnly={locked}
              className='form-control'
              type='text'
              name='entreprise'
              id='entreprise'
              placeholder='Company Name'
              {...register('entreprise', {
                required: 'Please enter your company name.',
              })}
            />
            {errors?.entreprise && (
              <p className='help-block text-danger my-2'>
                {errors.entreprise?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.fonction}
              readOnly={locked}
              className='form-control'
              type='text'
              name='fonction'
              id='fonction'
              placeholder='Designation, CEO, Manager etc.'
              {...register('fonction', {
                required: 'Please enter your role designation or title.',
              })}
            />
            {errors?.fonction && (
              <p className='help-block text-danger my-2'>
                {errors.fonction?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <select
              defaultValue={profile?.pays}
              // disabled={locked}
              id='pays'
              name='pays'
              className='form-select form-control'
              {...register('pays', {
                required: 'Please enter the country, you are based in.',
              })}
            >
              <option className='default-select' value=''>
                Select Your Location
              </option>
              {countries.map((obj, idx) => (
                <option
                  value={obj.name}
                  key={idx}
                  selected={obj.name === profile?.pays}
                >
                  {obj.name}
                </option>
              ))}
            </select>
            {errors?.pays && (
              <p className='help-block text-danger my-2'>
                {errors.pays?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              defaultValue={profile?.ville}
              readOnly={locked}
              className='form-control'
              type='text'
              name='ville'
              id='ville'
              placeholder='City or State'
              {...register('ville', {
                required: 'Please enter the city or state name.',
              })}
            />
            {errors?.ville && (
              <p className='help-block text-danger my-2'>
                {errors.ville?.message}
              </p>
            )}
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!locked}
                  onChange={(evt) => setLock(!evt.target.checked)}
                />
              }
              label='Modify'
            />
          </div>
          {apiErrs.validation && (
            <p className='help-block text-danger my-2'>
              {errors.validation?.message}
            </p>
          )}
          {apiErrs.length > 0 && apiErrs.map((obj) => mapErrs(obj))}
          <div className='newsletter'>
            <p className='help-block text-danger my-2'>{feedback}</p>
          </div>
          <div className='my-3'>
            <p>
              By clicking on update profile, you agree to Badgers'{' '}
              <a
                id='footer-link2'
                href='https://www.bdgrs.com/terms'
                target='blank'
              >
                Terms & Conditions{'  '}
              </a>{' '}
              <a
                id='footer-link2'
                href='https://www.bdgrs.com/privacy'
                target='blank'
              >
                and Privacy Policy.
              </a>
            </p>
          </div>
          <div className='row justify-content-center mt-4'>
            {spinner ? (
              <button type='' className={`btn ${styles.btnsignup}`}>
                Sending data ..
                <div
                  className='spinner-grow spinner-grow-sm'
                  role='status'
                ></div>
              </button>
            ) : (
              <button
                disabled={locked}
                type='submit'
                className={`btn ${styles.btnsignup}`}
              >
                Update Profile <FontAwesomeIcon icon='user-plus' />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileUpdate;
