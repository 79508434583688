// useApi.js
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import isLoggedIn from '../../utils/isLoggedIn';

const useProfile = (token, user) => {

  const url = `${process.env.REACT_APP_API}/api/user`;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  const fetchUser = () => {
     axios
       .get(`${url}/${user.userId}`, {
         headers: { authorization: `Bearer ${token}` },
       })
       .then((response) => {
         setLoading(false);
         setProfile(response.data);
       });
  };


  useEffect(() => {
    //check if user is logged in
    isLoggedIn(navigate, dispatch, '/user/update');
    fetchUser();
  }, []);

  return { loading, profile };
};

export default useProfile;
