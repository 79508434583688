import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useSearchParams } from 'react-router-dom';
import SEO from '../components/seo';
import Banner from '../components/banner';
import FooterProfile from '../components/footer_profile';
import PostCard from '../components/postcard';
import { useEffect, useState } from 'react';
import axios from 'axios';

const BlogQuery = () => {
  // Initial state and variables ---------------------------------------------------\\
  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');
  const url = `${process.env.REACT_APP_API}/api/blog/posts/search?q=${keyword}`;
  const [isloading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let active = true;
    (async () => {
     try {
             const response = await axios(url);
             if (active && response.status === 200) {
               setBlog(response.data);
               setLoading(false);
             } 
     } catch (err) {
         setLoading(false);
     }
 
    })();
    return () => {
      active = false;
      setBlog(null);
    };
  }, []);

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BDGRS Blog: Stay Informed with the Latest Updates`}
        page='blog'
        description={`Explore insightful updates on BDGRS blog. Stay informed about industry trends, tips, and news. Visit for the latest information and valuable insights.`}
        type='website'
        creator='Badgers SAS'
        keywords={`Mining equipment resale marketplace`}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/blog`}
        image='https://www.bdgrs.com/img/logo.png'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <Banner />
      <SEO
        title={`BDGRS Blog: Stay Informed with the Latest Updates`}
        page='blog'
        description={`Explore insightful updates on BDGRS blog. Stay informed about industry trends, tips, and news. Visit for the latest information and valuable insights.`}
        type='website'
        creator='Badgers SAS'
        keywords={`Mining equipment resale marketplace`}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/blog`}
        image='https://www.bdgrs.com/img/logo.png'
      />
      <div className='container'>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/blog'>
            Blog{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Keyword Search</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Badgers Blog: Keyword Search <FontAwesomeIcon icon='blog' />
          </h4>
        </div>
        {(blog && blog.length) ? (
          blog?.map((item) => {
            return <PostCard cle={item?.id.toString()} post={item} />;
          })
        ) : (
          <div>No blog posts were found with these keywords.</div>
        )}
      </div>
      <FooterProfile />
    </>
  );
};

export default BlogQuery;
