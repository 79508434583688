import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Filter from "../components/filter";
import Product from "../components/product";
import FooterProfile from "../components/footer_profile";
import Banner from "../components/banner";
import SEO from "../components/seo";


const Home = ({ displayMob }) => {

  //set initial states,listings and query filters
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [products, setProducts] = useState([]);
  const filters  = useSelector((state) => state.filters);


  //Get all products and equipments to display -------------\\
  useEffect(() => {
     const getProducts = async () => {
      const { categorie, searchStr, displayCount, page } = filters;
      // Get listings on page load..
      const response = await axios.get(
        `${url}/api/materiels?categorie=${categorie}&searchStr=${searchStr}&displayCount=${displayCount}&page=${page}`
      );
      const [count, products] = response.data;
      setProducts(products);
      setCount(count);

      setLoading(false);
    };

    const timeoutId = setTimeout(() => {
      getProducts();
    }, 300);

    return () => {clearTimeout(timeoutId); setLoading(false)};

  }, [filters]);



  // Component return .. .. listings or spinner;;
  return isLoading ? (
    // Waiting data loading after async request...
    <div className='loading'>
      <SEO
        title='Solution for Oil Industry Surplus Material - Badgers'
        page='Home'
        description='Surplus materials in the oil industry with our innovative solution for Badgers. Maximize efficiency with our tailored approach to managing surplus materials.'
        keywords={`Oil industry surplus material solutions`}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/`}
        type='website'
        creator='Badgers SAS'
        image='https://www.bdgrs.com/img/logo.png'
      />
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    //All products .. map and render product component
    <div className=''>
      <SEO
        title='Solution for Oil Industry Surplus Material - Badgers'
        page='Home'
        description='Surplus materials in the oil industry with our innovative solution for Badgers. Maximize efficiency with our tailored approach to managing surplus materials.'
        keywords={`Oil industry surplus material solutions`}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/`}
        type='website'
        creator='Badgers SAS'
        image='https://www.bdgrs.com/img/logo.png'
      />
      <Filter count={count} displayMob={displayMob} />
      <Banner />
      <div className='container main-pan'>
        {products.length > 0 &&
          products.map((product) => {
            return <Product product={product} token={token} key={product.id} />;
          })}
      </div>
      <FooterProfile />
    </div>
  );
};

export default Home;
