import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import SEO from '../components/seo';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import countries from '../data/countries.json';
import styles from './signup.module.css';

const Signup = () => {
  // Initial state and variables ---------------------------------------------------\\

  const url = `${process.env.REACT_APP_API}/api/`;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [feedback, setFeedback] = useState('');
  const [apiErrs, setApierrs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  // Check password complexity ---------------------------------------------------\\
  const isStrong = (password) => {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strongRegex.test(password);
  };

  // Handle signup ---------------------------------------------------\\
  const handleSignup = async (data) => {
    const {
      prenom,
      nom,
      email,
      contact,
      entreprise,
      fonction,
      pays,
      ville,
      password,
      c_password,
    } = data;
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');

    if (!isStrong(password)) {
      return setFeedback(
        'OOOOPS... Password does not satisfy complexity requirements. Please choose a password containing uppercase and lowercase characters, symbol (!, &, # etc) and numbers. Minimum length is 8 characters.'
      );
    }
    try {
      if (password !== c_password) {
        setFeedback(`OOOOPS... Passwords do not match.`);
      } else {
        const newUser = {
          prenom,
          nom,
          email,
          contact,
          entreprise,
          fonction,
          pays,
          ville,
          password,
          c_password,
        };
        setSpinner(true);
        const response = await axios.post(`${url}signup`, newUser, {headers: {origin: 'https://www.bdgrs.com'}});
        if (response.status === 201) {
          toast.success(response.data.message,
            {
              duration: 4000,
            }
          );
          setSpinner(false);
           setTimeout(() => {
            navigate('/user/signup/confirmation', {
              state: { user: response.data?.user, goTo: '/' },
            });
            setSpinner(false);
          }, 4000);
          
        } else {
          setFeedback(
            `Error Code: ${response.status}. \n OOOOPS.. ${response.msg}`
          );
           
        }
      }
    } catch (error) {
      setApierrs(error.response.data.errors);
       setSpinner(false);
    }
  };

  // Map API Errors ---------------------------------------------------\\
  const mapErrs = (obj) => {
    const keys = Object.keys(obj);
    return (
      <p className='help-block text-danger my-2'>
        {`${keys[0]}: `}
        {obj[keys[0]]}
      </p>
    );
  };

  // Rendered page ---------------------------------------------------\\
  return (
    <div className={styles.pan}>
      <SEO
        title={`BADGERS: Create your account`}
        page='member signup, create an account'
        description={`BADGERS:  member signup, create an account`}
        name='BADGERS'
        type='Users'
      />
      <Toaster position='top-center' reverseOrder={false} />
      <form onSubmit={handleSubmit(handleSignup)} className='row g-3' action=''>
        {/* ----------------------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/user/login'>
            Login{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Sign Up</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h5 className='h5'>
            Create Your Account <FontAwesomeIcon icon='user-plus' />
          </h5>
        </div>
        <div className={`${styles.card} row`}>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='text'
              name='prenom'
              id='prenom'
              placeholder='First Name'
              {...register('prenom', {
                required: 'Please enter your first name.',
                maxLength: 30,
                minLength: 3,
              })}
            />
            {errors?.prenom && (
              <p className='help-block text-danger my-2'>
                {errors.prenom?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='text'
              name='nom'
              id='nom'
              placeholder='Family Name'
              {...register('nom', {
                required: 'Please enter your surname.',
                maxLength: 30,
                minLength: 3,
              })}
            />
            {errors?.nom && (
              <p className='help-block text-danger my-2'>
                {errors.nom?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='email'
              name='email'
              id='email'
              placeholder='Email Address'
              {...register('email', {
                required: 'Please enter a valid email address.',
              })}
            />
            {errors?.email && (
              <p className='help-block text-danger my-2'>
                {errors.email?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='text'
              name='contact'
              id='contact'
              placeholder='Contact Number'
              {...register('contact', {
                required: 'Please enter a valid telephone number.',
              })}
            />
            {errors?.contact && (
              <p className='help-block text-danger my-2'>
                {errors.contact?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='text'
              name='entreprise'
              id='entreprise'
              placeholder='Company Name'
              {...register('entreprise', {
                required: 'Please enter your company name.',
              })}
            />
            {errors?.entreprise && (
              <p className='help-block text-danger my-2'>
                {errors.entreprise?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='text'
              name='fonction'
              id='fonction'
              placeholder='Designation, CEO, Manager etc.'
              {...register('fonction', {
                required: 'Please enter your role designation or title.',
              })}
            />
            {errors?.fonction && (
              <p className='help-block text-danger my-2'>
                {errors.fonction?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <select
              id='pays'
              name='pays'
              className='form-select form-control'
              {...register('pays', {
                required: 'Please enter the country, you are based in.',
              })}
            >
              <option className='default-select' value=''>
                Select Your Location
              </option>
              {countries.map((obj, idx) => (
                <option value={obj.name} key={idx}>
                  {obj.name}
                </option>
              ))}
            </select>
            {errors?.pays && (
              <p className='help-block text-danger my-2'>
                {errors.pays?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              className='form-control'
              type='text'
              name='ville'
              id='ville'
              placeholder='City or State'
              {...register('ville', {
                required: 'Please enter the city or state name.',
              })}
            />
            {errors?.ville && (
              <p className='help-block text-danger my-2'>
                {errors.ville?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              autocomplete='off'
              className='form-control'
              type='password'
              name='password'
              id='password'
              placeholder='Password, uppercase, lowercase, symbol (!, &, # etc) and number.'
              {...register('password', {
                required:
                  'Please choose a password containing uppercase and lowercase characters, symbol (!, &, # etc) and numbers. Minimum length is 8 characters.',
                minLength: 8,
              })}
            />
            {errors?.password && (
              <p className='help-block text-danger my-2'>
                {errors.password?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <input
              autocomplete='off'
              className='form-control'
              type='password'
              name='c_password'
              id='c_password'
              placeholder='Enter you password again.'
              {...register('c_password', {
                required: 'Please enter your password again.',
                minLength: 8,
              })}
            />
            {errors?.c_password && (
              <p className='help-block text-danger my-2'>
                {errors.c_password?.message}
              </p>
            )}
          </div>
          {apiErrs?.validation && (
            <p className='help-block text-danger my-2'>
              {errors.validation?.message}
            </p>
          )}
          {apiErrs?.length > 0 && apiErrs.map((obj) => mapErrs(obj))}
          <div className='newsletter'>
            <p className='help-block text-danger my-2'>{feedback}</p>
          </div>
          <div className='my-3'>
            <p id='agree'>
              <span>By clicking on sign up, you agree to Badgers' </span>
              <a
                id='footer-link2'
                href='https://www.bdgrs.com/terms'
                target='blank'
              >
                Terms & Conditions{'  '}
              </a>{' '}
              <a
                id='footer-link2'
                href='https://www.bdgrs.com/privacy'
                target='blank'
              >
                and Privacy Policy.
              </a>
            </p>
          </div>
          <div className='row justify-content-center mt-4'>
            {spinner ? (
              <button type='' className={`btn ${styles.btnsignup}`}>
                Sending data ..
                <div
                  className='spinner-grow spinner-grow-sm'
                  role='status'
                ></div>
              </button>
            ) : (
              <button type='submit' className={`btn ${styles.btnsignup}`}>
                Sign Up <FontAwesomeIcon icon='user-plus' />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Signup;
