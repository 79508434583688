import React from 'react';

const Piece = ({ articles, currency }) => {
  return (
    <table class='table table-responsive table-secondary table-striped'>
      <thead>
        <tr>
          <th scope='col'>N°</th>
          <th scope='col'>Article</th>
          <th scope='col'>Qty</th>
          <th scope='col'>Price ({currency === 'usd' ? 'US$' : '€'})</th>
          <th scope='col'>Total ({currency === 'usd' ? 'US$' : '€'})</th>
        </tr>
      </thead>
      <tbody>
        {articles.map((article, index) => (
          <tr key={article.materielId}>
            <td>{index + 1}</td>
            <td>{`${article?.Materiel?.brand}, ${article?.Materiel.title}`}</td>
            <td>{article.qty}</td>
            <td>{article.price.toFixed(2)}</td>
            <td>{(article.price * article.qty).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Piece;
