//Truncate .shorten names..text...
exports.truncate = (str, length) => {
  if (str.length > length) {
    return str.substring(0, length) + '..';
  }
  return str;
};


  //Which favourite icon to display
  exports.favIcon = (favs, field, elem) => {
    if (favs === null || favs === undefined) {
      return false;
    } else if (favs[field] && favs[field].indexOf(elem._id) !== -1) {
      return true;
    }
    return false;
  };