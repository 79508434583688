import { createSlice } from '@reduxjs/toolkit'
// Slice
const initialUser =  {
  userId: '',
  userName: '',
  userCity: '',
  userCountry: '',
  userPostcode: ''
}
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: initialUser,
    token: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      sessionStorage.setItem('user', JSON.stringify(action.payload.user));
      sessionStorage.setItem('token', action.payload.token);
    },

    refreshToken: (state, action) => {
      state.token = action.payload.token;
      sessionStorage.setItem('token', action.payload.token);
    },

    logoutSuccess: (state, action) => {
      state.user = initialUser;
      state.token = null;
      sessionStorage.clear();
    },
  },
});
  export default userSlice.reducer;
  // Actions
  export const { loginSuccess, logoutSuccess, refreshToken } = userSlice.actions;
  