import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState, React } from 'react';
import SEO from '../components/seo';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import countries from '../data/countries.json';
import styles from './../components/listingform.module.css';
import isLoggedIn from '../utils/isLoggedIn';

const Quote = () => {
  // Initial state and variables ---------------------------------------------------\\

  const url = `${process.env.REACT_APP_API}/api`;
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  
  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const { token } = useSelector((state) => state.user);
  
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const handleClose = () => {
      setOpen(false);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();

  // UseEffects, network calls ---------------------------------------------------\\

  useEffect(() => {
    if (error) {
      toast.error('Sorry something went wrong. Please try again!', {
        duration: 4000,
      });
      setSpinner(false);
    }
  }, [error]);

  // Query to get product data
  useEffect(() => {
    //check if user is logged in.
    isLoggedIn(navigate, dispatch, `/quote/${id}`);
    let active = true;
    (async () => {
      const productData = await axios(`${url}/product/${id}`);
      if (active) {
        setProduct(productData.data);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setProduct(null);
    };
  }, [id]);

  // Add upload success handler ---------------------------------------------------\\

  const upLoadSuccess = () => {
    reset();
    setSpinner(false);
    setOpen(true);
  };



  // Form submit handler ---------------------------------------------------\\
  const handleQuote = async (data) => {
    if (!spinner) {
      setSpinner(true);
    }
      try {
        const response = await axios.post(`${url}/quote/${id}`, data,  {
             headers: { authorization: `Bearer ${token}` }
           })
        if (response.status === 201) {
            upLoadSuccess()
        } 
      } catch (err) {
         toast.error(err.message, { duration: 4000});
        setSpinner(false);

      }
  };



  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Request a price quote`}
        page='request a price quote'
        description={`BADGERS: request a price quote`}
        name='BADGERS'
        type='Product'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <div className={styles.pan}>
      <SEO
        title={`BADGERS: Request a price quote`}
        page='request a price quote'
        description={`BADGERS: request a price quote`}
        name='BADGERS'
        type='Product'
      />
      <Toaster position='top-center' reverseOrder={false} />

      <form className='row g-3' onSubmit={handleSubmit(handleQuote)}>
        {/* ----------------------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/user/listings'>
            LISTINGS{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='link-span' to={`/product/${product.id}`}>
            {product.title}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>QUOTE REQUEST</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Request a Price Quote <FontAwesomeIcon icon='calculator' />
          </h4>
        </div>

        {/* ----------------------------------------------- */}

        <div className={`${styles.card} row mb-2 sellfirst-zone`}>
          <div className='mb-3 col-12'>
            <label htmlFor='title' className='form-label'>
              Product
            </label>
            <input
              defaultValue={product.title}
              disabled
              type='text'
              id='title'
              name='title'
              placeholder='Choose a brief title for your listing.'
              className='form-control'
            />
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='brand' className='form-label'>
              Manufacturer, Brand
            </label>
            <input
              defaultValue={product?.brand}
              disabled
              type='text'
              id='brand'
              name='brand'
              placeholder='Please enter product manufacturer or brand name.'
              className='form-control'
            />
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='condition' className='form-label'>
              Condition
            </label>
            <input
              defaultValue={product?.condition}
              disabled
              type='text'
              id='condition'
              name='condition'
              placeholder='Please enter product manufacturer or brand name.'
              className='form-control'
            />
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='prix' className='form-label'>
              Catalogue Price
            </label>
            <input
              defaultValue={`${product.price} ${
                product?.currency === 'usd' ? '$' : '€'
              }`}
              disabled
              type='text'
              id='prix'
              name='prix'
              className='form-control'
            />
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='qty' className='form-label'>
              Available Units
            </label>
            <input
              defaultValue={product?.qty}
              disabled
              type='number'
              id='qty'
              name='qty'
              className='form-control'
            />
          </div>
        </div>

        {/* ----------------------------------------------- */}

        <div className={`${styles.card} row mb-2 sellsecond-zone`}>
          <div className='my-2 col-md-6'>
            <label htmlFor='country' className='form-label'>
              Delivery Location
            </label>
            <select
              id='type'
              name='type'
              className='form-select form-control'
              {...register('country', {
                required:
                  'Please select a country from the list, where product is to be delivered.',
                minLength: 3,
              })}
            >
              <option className='default-select' value=''>
                Select Delivery Location
              </option>
              {countries.map((obj, idx) => (
                <option
                  value={obj.name}
                  key={idx}
                  selected={obj.name === product?.country}
                >
                  {obj.name}
                </option>
              ))}
            </select>
            {errors?.country && (
              <p className='help-block text-danger my-2'>
                {errors.country?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='units' className='form-label'>
              Required Quantity
            </label>
            <input
              type='number'
              id='units'
              name='units'
              className='form-control'
              {...register('units', {
                required: 'Quantity is required.',
                min: {
                  value: 1,
                  message: 'Please a enter a valid quantity.',
                },
                max: {
                  value: 5000000,
                  message: 'Quantity exceeds the maximum allowed.',
                },
              })}
            />
            {errors?.units && (
              <p className='help-block text-danger my-2'>
                {errors.units?.message}
              </p>
            )}
          </div>
          <div className='col-12'>
            <label htmlFor='message' className='form-label'>
              Your Message
            </label>
            <textarea
              name='message'
              placeholder='Please tell us about your needs, specifications etc.'
              id='message'
              className='form-control'
              rows='4'
              {...register('message', {
                required: 'Please enter your message.',
                minLength: {
                  value: 20,
                  message:
                    'Your message is not detailed enough. Please write a few phrases.',
                },
                maxLength: {
                  value: 1500,
                  message:
                    'Message lenght exceeds the maximum allowed. 1500 characters.',
                },
              })}
            ></textarea>
            {errors?.message && (
              <p className='help-block text-danger my-2'>
                {errors.message?.message}
              </p>
            )}
          </div>

          {!spinner && (
            <div className='d-flex gap-2 my-3'>
              <Link
                className={`btn btn-danger ${styles.btncancel}`}
                to={`/product/${product.id}`}
              >
                Cancel
              </Link>
              <button type='submit' className={`btn ${styles.btnsubmit}`}>
                Request Quote {'  '}
                <FontAwesomeIcon icon='paper-plane' />
              </button>
            </div>
          )}
          {spinner && (
            <div className='d-grid gap-2 my-3'>
              <button className={`btn ${styles.btnsubmit}`}>
                Sending data...
                <div
                  className='spinner-grow spinner-grow-sm'
                  role='status'
                ></div>
              </button>
            </div>
          )}
        </div>
        {/* ----------------------------------------------- */}

        <div>
          <Dialog onClose={handleClose} open={open}>
            <DialogContent dividers>
              <img
                src='/img/success-icon-23194.png'
                alt='sell-eqpt success'
                style={{
                  width: '20%',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
              <h3>
                Thanks for trust in Badgers. We have received your price quote
                request and will get back to you shortly.
              </h3>
            </DialogContent>
            <DialogActions>
              <Link to='/user/listings'>
                <Button variant='outlined' autoFocus onClick={handleClose}>
                  Listings
                </Button>
              </Link>
              <Link to={`/product/${product.id}`}>
                <Button variant='outlined' autoFocus onClick={handleClose}>
                  Product Page
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </div>
      </form>
    </div>
  );
};


export default Quote;