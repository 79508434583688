import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link, useLocation } from "react-router-dom";
import React,{ useEffect, useState} from 'react';
import SEO from "../components/seo";
import {useDispatch} from 'react-redux'
import { useForm } from "react-hook-form";
import {loginSuccess, logoutSuccess} from '../redux/user';
import { setFav } from "../redux/favs";
import ParseJwt from '../tokenComponent';
import getFavs from "../utils/getFavs";
import SignupCandy from "../components/signup_candy";


const Login = () => {

  //set initial states, comics, characters and query filters
   
  const url = `${process.env.REACT_APP_API}/auth/`;
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const goTo = location.state && location.state.goTo


    // UseEffects, check if there's a user and token ---------------------------------------------------\\

  useEffect(() => {
    if (user && token) {
      const decodedJwt = ParseJwt(token);
      if (decodedJwt.exp * 1000 >= Date.now()) {
        dispatch(logoutSuccess)
        return navigate(-1);
      }
    }
  }, []);
  

  // Login form submit handler ---------------------------------------------------\\

  const onSubmit = (data) => {
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
    let collection = {
      email: data.email,
      password: data.password,
    };
    var raw = JSON.stringify(collection);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    fetch(url + 'login', requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          let res = response.text();
          res.then((data) => {
            setError(JSON.parse(data).error);
          });
          throw new Error();
        }
      })
      .then((result) => {
        dispatch(loginSuccess(JSON.parse(result)));
        getFavs(JSON.parse(result).token).then(res => dispatch(setFav(res.data)));
        goTo ? navigate(goTo) : navigate(-1);
      })
      .catch((error) => console.log(error.message));
  };

  

  return (
    <div className='form-pan'>
      <SEO
        title={`BADGERS: Member login`}
        page='user login'
        description={`BADGERS: user login`}
        name='BADGERS'
        type='Users'
      />
      <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
        <h4 className='h4'>
          Member Login <FontAwesomeIcon icon='sign-in-alt' />
        </h4>
        <input
          type='email'
          id='email'
          name='email'
          className='input-txt email'
          placeholder='Email Address'
          {...register('email', {
            required: 'Please enter a valid email address.',
          })}
        />
        {errors?.email && (
          <p className='help-block text-danger'>{errors.email?.message}</p>
        )}
        <input
          type='password'
          id='password'
          name='password'
          autocomplete='current-password'
          className='form-control email'
          placeholder='Password'
          {...register('password', {
            required: 'Please enter your password.',
          })}
        />
        <p className='help-block text-danger'></p>
        {errors?.password && (
          <p className='help-block text-danger'>{errors.password?.message}</p>
        )}
        {error && (
          <p className='help-block' style={{ color: 'red' }}>
            {error}
          </p>
        )}
        <Link id='link3' to='/user/forgot'>
          Forgot your password? <FontAwesomeIcon icon='unlock' />
        </Link>
        <button type='submit' className='btn-login'>
          Login <FontAwesomeIcon icon='sign-in-alt' />
        </button>
      </form>
      <SignupCandy />
    </div>
  );
};

export default Login;




