import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import SEO from '../components/seo';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';


const Forgot = () => {
  //set initial states, comics, characters and query filters

  const url = `${process.env.REACT_APP_API}/auth/forgot`;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [feedback, setFeedback] = useState();




  // Login form submit handler ---------------------------------------------------\\

  const onSubmit = (data) => {

    if(error) {setError(null)};
    if(feedback) {setFeedback(null)}

    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
    let collection = {
      email: data.email,
    };
    var raw = JSON.stringify(collection);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          let res = response.text();
          res.then((data) => {
            setError(JSON.parse(data).error);
          });
          throw new Error();
        }
      })
      .then((result) => {
        const data = JSON.parse(result);
        setFeedback(data?.message)
      })
      .catch((error) => console.log(error.message));
  };



  return (
    <div className='form-pan'>
      <SEO
        title={`BADGERS: Forgot password`}
        page='forgot password'
        description={`BADGERS: forgot password`}
        name='BADGERS'
        type='Users'
      />
      <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
        <div>
          Forgot Password <FontAwesomeIcon icon='fingerprint' />
        </div>
        <input
          type='email'
          id='email'
          name='email'
          className='input-txt email'
          placeholder='Email Address'
          {...register('email', {
            required: 'Please enter a valid email address.',
          })}
        />
        {errors?.email && (
          <p className='help-block text-danger'>{errors.email?.message}</p>
        )}
        {error && (
          <p className='help-block' style={{ color: 'red' }}>
            {error}
          </p>
        )}
        {feedback && (
          <p className='help-block' style={{ color: 'green' }}>
            {feedback}
          </p>
        )}
        <Link id='link3' to='/user/signup'>
          Not a Member Yet? Signup <FontAwesomeIcon icon='user-plus' />
        </Link>
        {/* <p className="newsletter">{feedback}</p> */}
        <button type='submit' className='btn-login'>
          Reset Password <FontAwesomeIcon icon='fingerprint' />
        </button>
      </form>
    </div>
  );
};

export default Forgot;
