import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { truncate } from '../utils/utils';
import { excerptText } from '../utils/excerptText';
import styles from './postcard.module.css';

const PostCard = ({ post, cle }) => {
  const navigate = useNavigate();
  const imgUri = post.content.match(/"http([^">]+")/g)


  return (
    <div
      onClick={() =>
        navigate(
          `/blog/post/${post.title.trim().split(' ').join('-').toLowerCase()}.html`
        )
      }
      className={styles.card}
      key={cle}
    >
      <div className={styles.postdetails}>
        <div className=''>
          <h2
            className='h2'
            dangerouslySetInnerHTML={{
              __html: post.title,
            }}
          />
          <h5 className='my-4 h5'>
            Last updated: {new Date(post.updated).toLocaleString()}
          </h5>
          <p>{excerptText(post.content, 400)}</p>
          <p className='my-3'>READ MORE ...</p>
        </div>
      </div>
      <div className={styles.imgdiv}>
        <img src={imgUri[0].slice(1, -1)} alt='' />
      </div>
    </div>
  );
};

export default PostCard;
