import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useEffect, useState } from 'react';
import SEO from '../components/seo';
import styles from './checkout.module.css';
import FooterProfile from '../components/footer_profile';
import CheckoutForm from '../components/checkoutForm';
import { useDispatch } from 'react-redux';
import isLoggedIn from '../utils/isLoggedIn';
//Stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const Checkout = () => {
  // Initial state and variables ---------------------------------------------------\\
  // Get a specific query parameter
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
  const [searchParams, setSearchParams] = useSearchParams();
  const cartid = searchParams.get('cartid');
  const dispatch = useDispatch()

  const url = `${process.env.REACT_APP_API}/api`;

  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const [clientSecret, setClientSecret] = useState('');
  const [price, setPrice] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [total, setTotal] = useState(null);
  const [currency, setCurrency] = useState(null);
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();


  //check if user is logged in / get transaction details
  useEffect(() => {
    //user login check
    isLoggedIn(navigate, dispatch, `/checkout?cartid=${cartid}`);
    
    //get cart price and transaction details
    axios
      .get(`${url}/checkout/cart/${cartid}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          const { price, shipping, total, currency, clientSecret } =
            response.data;
          setClientSecret(clientSecret);
          setTotal(total);
          setCurrency(currency);
          setShipping(shipping);
          setPrice(price);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(
            `${response.data.message} \nError Code: ${response.status}`,
            { duration: 5000 }
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          `${err.response.data.message} \nError Code: ${err.response.status}`,
          { duration: 5000 }
        );
      });
  }, [token]);

  // Form submit handler ---------------------------------------------------\\

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Checkout`}
        page='checkout'
        description={`BADGERS: checkout`}
        name='BADGERS'
        type='checkout'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <SEO
        title={`BADGERS: Checkout`}
        page='checkout'
        description={`BADGERS: Checkout`}
        name='BADGERS'
        type='checkout'
      />
      <div className='container'>
        <Toaster position='top-center' reverseOrder={false} />
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/cart'>
            Cart{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/delivery'>
            Delivery{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Checkout</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Checkout <FontAwesomeIcon icon='credit-card' />
          </h4>
        </div>

        <div>
          <div className={`${styles.card}`}>
            <div className={styles.pricepan}>
              <div className={styles.cartvalue}>
                <div>Sub Total</div>
                <div>{`${price?.toFixed(2)} ${
                  currency === 'usd' ? 'US$' : '€'
                }`}</div>
              </div>
              <div className={styles.cartvalue}>
                <div>Delivery Charges</div>
                <div>{`${shipping} ${currency === 'usd' ? 'US$' : '€'}`}</div>
              </div>
              <div className={styles.cartvalue}>
                <div>Grand Total</div>
                <div>
                  <strong>
                    {total} {currency === 'usd' ? 'US$' : '€'}
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <Elements
                options={{
                  clientSecret: clientSecret,
                  appearance: {
                    theme: 'stripe',
                  },
                }}
                stripe={stripePromise}
              >
                <CheckoutForm total={total} currency={currency} />
              </Elements>
            </div>
          </div>
        </div>
      </div>
      <FooterProfile />
    </>
  );
};

export default Checkout;
