import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './post.module.css';
import Banner from '../components/banner';
import { useEffect, useState } from 'react';
import FooterProfile from '../components/footer_profile';
import axios from 'axios';

const Post = () => {
  // Initial state and variables ---------------------------------------------------\\
  const { postTitle } = useParams();
  const url = `${process.env.REACT_APP_API}/api/blog/post/${postTitle.split('.')[0]}`;
  const [isloading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    let active = true;
    (async () => {
      const { data } = await axios(url);
      if (active) {
        setPost(data);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setPost(null);
    };
  }, []);

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <Banner />
      <SEO
        title={`BADGERS BLOG: ${post.title}`}
        page={`${post.title}`}
        description={`Explore insightful updates on BDGRS blog. Stay informed about industry trends, tips, and news. Visit for the latest information and valuable insights.`}
        type='website'
        creator='Badgers SAS'
        keywords={post?.labels.join(', ')}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/blog/post/${postTitle}`}
      />

      <div className={`container`}>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/blog'>
            Blog{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>{post.title}</span>
        </div>
        <div className={`${styles.pan} my-2`}>
          <div>
            <h1 className='h1 my-5'>{post.title}</h1>
            <div className='py-4'>
              <span>
                {post?.labels.map((label) => {
                  return (
                    <a
                      className={styles.keyword}
                      href={`/blog/posts/search?keyword=${label
                        .split(' ')
                        .join('%')}`}
                      rel='blog'
                    >
                      {label}
                    </a>
                  );
                })}
              </span>
            </div>
            <h5 className='my-4 h5'>
              Last updated: {new Date(post.updated).toLocaleString()}
            </h5>
          </div>
          <div
            className={styles.post}
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          />
        </div>
      </div>

      <FooterProfile />
    </>
  );
};

export default Post;
