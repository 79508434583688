import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SEO from '../components/seo';
import FooterProfile from '../components/footer_profile';
import { useDispatch } from 'react-redux';
import { resetCart } from '../redux/cart';
import axios from 'axios';
import styles from './confirm.module.css';
import isLoggedIn from '../utils/isLoggedIn';

const ConfirmOrder = () => {
  // Initial state and variables ---------------------------------------------------\\
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const pi = searchParams.get('payment_intent');
  const url = `${process.env.REACT_APP_API}/api`;

  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const [piStatus, setStatus] = useState(null);
  const [err, setErr] = useState(null);
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();

  //check if user is logged in
  useEffect(() => {
   isLoggedIn(navigate, dispatch, '/user/orders');

    //get cart price and transaction details
    axios
      .get(`${url}/checkout/pay/${pi}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const { piStatus } = response.data;
          setStatus(piStatus);
          dispatch(resetCart());
          setLoading(false);
        }
      })
      .catch((err) => {
        setErr(
          `${err?.response?.data?.message} \nError Code: ${err?.response?.status}`
        );
        setLoading(false);
      });
  }, [pi, token]);

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Order Confirmation`}
        page='order'
        description={`BADGERS: Order Confirmation`}
        name='BADGERS'
        type='order'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <SEO
        title={`BADGERS: Order Confirmation`}
        page='order'
        description={`BADGERS: Order Confirmation`}
        name='BADGERS'
        type='order'
      />

      <div className='container'>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Order Confirmation</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <div className={`${styles.servicecard} `}>
            {piStatus === 'succeeded' ? (
              <>
                <h4 className='h4 text-center'>
                  Your order is confirmed. Thank you.{' '}
                  <FontAwesomeIcon icon='check-square' />
                </h4>
                <div>
                  <img
                    src='/img/order_confirm.png'
                    alt='Loading'
                    className={styles.envelope}
                  />
                </div>
                <div>
                  Your order has been placed. You will receive an email with
                  your order details shortly. You can track your{' '}
                  <a href='/user/orders'>orders here</a>.
                </div>
              </>
            ) : undefined}

            {/* Order Confirmation >>>>>>>>>>>>>> */}

            {piStatus === 'payment_failed' ? (
              <>
                <h4 className='h4 text-center'>
                  Payment Failed <FontAwesomeIcon icon='exclamation-circle' />
                </h4>
                <div>
                  <img
                    src='/img/order_confirm.png'
                    alt='Loading'
                    className={styles.envelope}
                  />
                </div>
                <div>
                  We are are sorry, there was an error processing your payment.
                  Please try again with a different payment method.
                </div>
              </>
            ) : undefined}
            {piStatus === 'processing' ? (
              <>
                <h4 className='h4 text-center'>
                  Order Processing <FontAwesomeIcon icon='exclamation-circle' />
                </h4>
                <div>
                  <img
                    src='/img/order_confirm.png'
                    alt='Loading'
                    className={styles.envelope}
                  />
                </div>
                <div>
                  Hold tight, your order is being processed. We will email you
                  when your order succeeds. Thank You.
                </div>
              </>
            ) : undefined}
            {err ? (
              <>
                <h4 className='h4 text-center'>
                  Something Went Wrong{' '}
                  <FontAwesomeIcon icon='exclamation-circle' />
                </h4>
                <div>
                  <img
                    src='/img/order_confirm.png'
                    alt='Loading'
                    className={styles.envelope}
                  />
                </div>
                <div>{err}</div>
              </>
            ) : undefined}
          </div>
        </div>
      </div>

      <FooterProfile />
    </>
  );
};

export default ConfirmOrder;
