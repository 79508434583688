import { createSlice } from '@reduxjs/toolkit';

  // Slice
  const initialFilters = {

      categorie: '',
      searchStr: '',
      displayCount: 20,
      page: 1,
    
  };

  const filterSlice = createSlice({
    name: 'filters',
    initialState: initialFilters,
    reducers: {
      setFilters: (state, action) => {
         const {categorie, searchStr} = action.payload
         state.categorie = categorie;
         state.searchStr = searchStr;
         state.page = 1;
      },

      setDisplayCount: (state, action) => {
         state.displayCount = action.payload;
         state.page = 1;
      },

      setPage: (state, action) => {
         state.page = action.payload;
      },

      clearFilters: (state, action) => {
        state = initialFilters;
      },
      nextPage: (state, action) => {
        state.page++
      },
      prevPage: (state, action) => {
        state.page--
      }
    },
  });
  export default filterSlice.reducer;

  // Actions
  export const { setFilters, clearFilters, setDisplayCount, setPage, nextPage, prevPage } = filterSlice.actions;