import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useEffect, useState } from 'react';
import SEO from '../components/seo';
import FooterProfile from '../components/footer_profile';
import Order from '../components/checkout/order';
import {  useDispatch } from 'react-redux';
import isLoggedIn from '../utils/isLoggedIn';

const Orders = () => {
  // Initial state and variables ---------------------------------------------------\\
  const url = `${process.env.REACT_APP_API}/api`;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const token = sessionStorage.getItem('token');
  const [orders, setOrders] = useState([]);

  //check if user is logged in
  useEffect(() => {
    isLoggedIn(
      navigate,
      dispatch,
      '/user/orders'
    );
    (async () => {
      let active = true;
      const response = await axios(`${url}/orders`, {
        headers: { authorization: `Bearer ${token}` },
      });
      const { orders } = response.data;
      if (active) {
        setOrders(orders);
        setLoading(false);
      }
    })();

    return () => {
      setLoading(true);
      setOrders([]);
    };
  }, [token]);

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Orders`}
        page='orders'
        description={`BADGERS: Orders`}
        name='BADGERS'
        type='orders'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <SEO
        title={`BADGERS: Orders`}
        page='orders'
        description={`BADGERS: Orders`}
        name='BADGERS'
        type='orders'
      />
      <div className='container'>
        <Toaster position='top-center' reverseOrder={false} />
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/user/update'>
            User{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Orders</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Orders <FontAwesomeIcon icon='shopping-basket' />
          </h4>
        </div>

        {/* ------------------orders----------------------------- */}
        {orders.length ? (
          orders.map((order) => <Order order={order} />)
        ) : (
          <>You do not have any ongoing orders.</>
        )}
      </div>
      <FooterProfile />
    </>
  );
};

export default Orders;
