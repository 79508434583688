import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import SEO from '../components/seo';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import styles from './signup.module.css';

const ResendVerif = () => {
  // Initial state and variables ---------------------------------------------------\\

  const [feedback, setFeedback] = useState('');
  const [apiErrs, setApierrs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();



  const url = `${process.env.REACT_APP_API}/auth`;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    reValidateMode: 'onSubmit',
  });




  // Handle form submit ---------------------------------------------------\\
  const handleResendVerif = async (data) => {
    setSpinner(true);
    setApierrs([]);
    const { email } = data;

    try {
      const body = {
        email,
      };
      setSpinner(true);
      const response = await axios.post(
        `${url}/resendveriflink`,
        body
      );
      if (response.status === 201) {
        setSpinner(false);
        reset();
        toast.success(response.data.message, {
          duration: 2500,
        });
        setFeedback(response.data.message);
        setTimeout(() => navigate('/user/login'), 3000);
      } 
    } catch (error) {
      setSpinner(false);
      setApierrs(error.response.data.errors);
    }
  };

  // Map API Errors ---------------------------------------------------\\
  const mapErrs = (obj, idx) => {
    const keys = Object.keys(obj);
    return (
      <p className='help-block text-danger my-2' key={idx}>
        {`${keys[0].toUpperCase()}: `}
        {obj[keys[0]]}
      </p>
    );
  };

  // Rendered page ---------------------------------------------------\\
  return (
    <div className={styles.pan}>
      <SEO
        title={`BADGERS: Verify your email`}
        page='verify your email'
        description={`BADGERS: verify your email`}
        name='BADGERS'
        type='Users'
      />
      <Toaster position='top-center' reverseOrder={false} />
      <form
        onSubmit={handleSubmit(handleResendVerif)}
        className='row g-3'
        action=''
      >
        {/* ----------------------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Email Verification</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h5 className='h5'>
            Resend Verification Link <FontAwesomeIcon icon='envelope' />
          </h5>
        </div>
        <div className={`${styles.card} row`}>
          <div className='my-2 col-md-12'>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <input
              autoComplete='off'
              className={`form-control`}
              type='email'
              name='email'
              id='email'
              placeholder='Please enter your account sign up email.'
              {...register('email', {
                required: 'Please enter your email address.',
              })}
            />
            {errors?.email && (
              <p className='help-block text-danger my-2'>
                {errors.email?.message}
              </p>
            )}
          </div>

          {apiErrs?.validation && (
            <p className='help-block text-danger my-2'>
              {errors.validation?.message}
            </p>
          )}
          {apiErrs.length > 0 && apiErrs.map((obj, idx) => mapErrs(obj, idx))}
          {feedback && (
            <div className='newsletter'>
              <p className='help-block text-danger my-2'>{feedback}</p>
            </div>
          )}
          <div className='my-3'>
            <p>
              By clicking on resend link, you agree to Badgers'{' '}
              <a id='footer-link2' href='https://www.bdgrs.com/' target='blank'>
                Terms & Conditions{'  '}
              </a>{' '}
              <a id='footer-link2' href='https://www.bdgrs.com/' target='blank'>
                and Privacy Policy.
              </a>
            </p>
          </div>
          <div className='row justify-content-center mt-4'>
            {spinner ? (
              <button type='' className={`btn ${styles.btnsignup}`}>
                Sending data ..
                <div
                  className='spinner-grow spinner-grow-sm'
                  role='status'
                ></div>
              </button>
            ) : (
              <button type='submit' className={`btn ${styles.btnsignup}`}>
                Resend Link <FontAwesomeIcon icon='envelope' />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );

};

export default ResendVerif;
