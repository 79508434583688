import './App.css';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import JsonData from './data/data.json';
import { useDispatch } from 'react-redux';
import { loginSuccess, logoutSuccess, refreshToken } from './redux/user';
import { setFav } from './redux/favs';
import ParseJwt from './tokenComponent';
import { useState, useEffect } from 'react';
import Home from './pages/home';
import Favourites from './pages/favourites';
import UserListings from './pages/userlistings';
import Navbar from './components/navbar';
import Login from './pages/login';
import Signup from './pages/signup';
import Forgot from './pages/forgot';
import ResetPassword from './pages/reset_pw';
import EmailVerif from './pages/emailVerif';
import ResendVerif from './pages/resendVerif';
import Footer from './components/footer';
import Product from './pages/product';
import Listing from './pages/listing';
import ProfileUpdate from './pages/profileUpdate';
import EditListing from './pages/edit_listing';
import Settings from './pages/settings';
import Values from './pages/our_values';
import Services from './pages/services';
import Industries from './pages/industries';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import CookiePolicy from './pages/cookies';
import SignupSuccess from './pages/signup_success';
import Quote from './pages/quote';
import Blog from './pages/blog';
import Post from './pages/post';
import Cart from './pages/cart';
import Delivery from './pages/delivery';
import Checkout from './pages/checkout';
import ConfirmOrder from './pages/confirmOrder';
import BlogQuery from './pages/blogQuery';
import Orders from './pages/orders';
import axios from 'axios';

import {
  faSignOutAlt,
  faSignInAlt,
  faSmileWink,
  faUserPlus,
  faHeart,
  faPlusCircle,
  faTh,
  faForward,
  faBars,
  faStepBackward,
  faStepForward,
  faUnlock,
  faPlus,
  faBullhorn,
  faUpload,
  faHome,
  faChevronRight,
  faBarcode,
  faUser,
  faStar,
  faKey,
  faWrench,
  faPenSquare,
  faTrash,
  faCalculator,
  faPaperPlane,
  faPeopleArrows,
  faBalanceScale,
  faFingerprint,
  faEyeSlash,
  faCookie,
  faGlobe,
  faFan,
  faEnvelope,
  faCartPlus,
  faPuzzlePiece,
  faCheck,
  faBlog,
  faCreditCard,
  faAngleRight,
  faShoppingCart,
  faTruckMoving, faCheckSquare, faShoppingBasket, faExclamationCircle, faIndustry
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(
  faSignOutAlt,
  faSignInAlt,
  faSmileWink,
  faUserPlus,
  faHeart,
  faPlusCircle,
  faTh,
  faForward,
  faBars,
  faStepBackward,
  faStepForward,
  faUnlock,
  faPlus,
  faBullhorn,
  faUpload,
  faHome,
  faChevronRight,
  faBarcode,
  faUser,
  faStar,
  faKey,
  faWrench,
  faPenSquare,
  faTrash,
  faCalculator,
  faPaperPlane,
  faPeopleArrows,
  faBalanceScale,
  faFingerprint,
  faEyeSlash,
  faCookie,
  faGlobe,
  faFan,
  faEnvelope,
  faCartPlus,
  faPuzzlePiece,
  faCheck,
  faBlog,
  faCreditCard,
  faAngleRight,
  faShoppingCart, faTruckMoving, faCheckSquare, faShoppingBasket, faExclamationCircle, faIndustry
);

function App() {
  const url = `${process.env.REACT_APP_API}/api`;
  const [landingPageData, setLandingPageData] = useState({});
  const dispatch = useDispatch();

  //  Refresh JWT at intervals---------------------------------------------------\\

  const refreshJWT = async () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const decodedJwt = ParseJwt(token);
      if (decodedJwt?.exp * 1000 > Date.now()) {
        axios
          .get(`${process.env.REACT_APP_API}/auth/refresh`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const refreshedToken = response.data.token;
            dispatch(refreshToken({ token: refreshedToken }));
          })
          .catch((err) => {
            console.log(err);
            dispatch(logoutSuccess());
          });
      }
    }
  };

  //  UseEffects---------------------------------------------------\\

  useEffect(() => {
    const startUp = async () => {
      setLandingPageData(JsonData);
      setInterval(refreshJWT, 14 * 60 * 1000);
      const user = JSON.parse(sessionStorage.getItem('user'));
      const token = sessionStorage.getItem('token');
      if (token && user) {
        const decodedJwt = ParseJwt(token);
        if (decodedJwt?.exp * 1000 > Date.now()) {
          dispatch(loginSuccess({ user, token }));
          const favs = await axios.get(`${url}/favs`, {
            headers: { authorization: `Bearer ${token}` },
          });
          if (favs.status === 200) {
            dispatch(setFav(favs.data));
          }
        }
      }
    };
    startUp();
  }, []);

  // Set user (name) and token and favourites
  // const [favs, setFavs] = useState({ products: [] });
  const [displayMob, setDisplayMob] = useState(false);

  return (
    <div>
      <Router>
        <Navbar displayMob={displayMob} setDisplayMob={setDisplayMob} />
        <Routes>
          <Route path='/' element={<Home displayMob={displayMob} />} />
          <Route
            path='/favourites'
            element={<Favourites displayMob={displayMob} />}
          />
          <Route path='/product/:id' element={<Product />} />
          <Route path='/quote/:id' element={<Quote />} />
          <Route path='/user/signup' element={<Signup />} />
          <Route path='/user/signup/confirmation' element={<SignupSuccess />} />
          <Route path='/user/login' element={<Login />} />
          <Route path='/user/forgot' element={<Forgot />} />
          <Route path='/user/reset/:token' element={<ResetPassword />} />
          <Route path='/user/emailverif/:token' element={<EmailVerif />} />
          <Route path='/user/resendverif' element={<ResendVerif />} />
          <Route path='/user/update' element={<ProfileUpdate />} />
          <Route path='/user/settings' element={<Settings />} />
          <Route path='/user/orders' element={<Orders />} />
          <Route path='/listing/add' element={<Listing />} />
          <Route path='/listing/edit/:id' element={<EditListing />} />
          <Route path='/user/listings' element={<UserListings />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/delivery' element={<Delivery />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/confirmorder' element={<ConfirmOrder />} />
          <Route path='/values' element={<Values />} />
          <Route path='/services' element={<Services />} />
          <Route path='/industries' element={<Industries />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/cookies' element={<CookiePolicy />} />
          <Route path='/about' element={<Values />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/post/:postTitle' element={<Post />} />
          <Route path='/blog/posts/search' element={<BlogQuery />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
