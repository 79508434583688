import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './info.module.css';
import Banner from '../components/banner';

const Services = () => {
  // Initial state and variables ---------------------------------------------------\\

  return (
    <>
      <Banner />
      <SEO
        title={`Integrated Solutions for Oil & Gas, Energy, Mining, and More`}
        page='our services'
        description={`Explore seamless project execution with our project management services. We ensure that your project is executed efficiently, on time, and within budget.`}
        type='website'
        creator='Badgers SAS'
        keywords={`Oil industry material management services`}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/services`}
        image='https://www.bdgrs.com/img/logo.png'
      />
      <div className={'container'}>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Our Services</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Our Services <FontAwesomeIcon icon='people-arrows' />
          </h4>
        </div>
        <div className='row my-6 justify-content-center' action=''>
          <div className={`${styles.servicecard} col-12 g-4`}>
            <h5 className='h5'>
              CONSULTING <FontAwesomeIcon icon='puzzle-piece' />
            </h5>
            <div className={styles.desc}>
              <p>
                Refine your strategy with the specialized consulting expertise
                of BADGERS. Rooted in diverse applications, our consulting
                services are laser-focused on optimizing every facet of your
                project or operations. Specializing in oil & gas industry
                services and mining sector consultancy, we will provide value to
                your company.
              </p>
              <p>
                From efficient project design to optimal project execution
                through engineering studies and securing the best value for your
                investment with procurement services, we ensure a tailored
                approach. Our field supervision services guarantee a safe and
                regulatory-compliant selling process. Partnering with us unlocks
                a plethora of benefits. Leverage our ecosystem of highly skilled
                consultants with extensive experience, ensuring your project and
                operations receive top-tier guidance.
              </p>
              <p>
                Our professional and agile team provides the best possible
                service, adapting to your needs. With the ability to deploy our
                team internationally, WE ensure a seamless process, regardless
                of location. Commitment to quality is at the core of our ethos,
                guaranteeing that your project and operations are executed to
                the highest standards.
              </p>
              <p>
                Contact us to tap into oil & gas and mining sector services that
                transform your project and operations challenges into strategic
                advantages.
              </p>
            </div>

            <div>
              <a
                className={`${styles.quote} ${styles.btn}`}
                id='contact-us'
                href='mailto:info@bdgrs.com'
                aria-label='contact-us'
                target='blank'
              >
                Contact us <FontAwesomeIcon icon='envelope' />
              </a>
            </div>
          </div>
          <div className={`${styles.servicecard} col-lg-6 g-4`}>
            <h5 className='h5'>
              MARKETPLACE <FontAwesomeIcon icon='cart-plus' />
            </h5>
            <div className={styles.desc}>
              <p>
                Navigating the dynamic landscape of surplus materials is
                simplified with BADGERS, your trusted partner for streamlined
                selling. Our surplus material marketplace spans industries,
                specializing in services tailored for the oil & gas and mining
                sectors. This platform offers a dual benefit, not only
                facilitating the identification of suitable buyers but also
                championing a reduction in CO2 emissions. Materials listed for
                buyers undergo meticulous quality assurance procedures, ensuring
                a seamless and trustworthy purchasing experience.
              </p>
              <p>
                Take advantage of features such as secure personal space
                authentication, profile updates, uploading, and managing
                listings, alongside browsing and bookmarking equipment. Our
                surplus inventory management services present two distinctive
                packages—standard and premium. The standard package encompasses
                market surveying, materials listing, product promotion, and
                sales opportunity management, with the added option to segment
                your inventory. Opt for the premium package to unlock a
                comprehensive solution, covering all standard services and
                incorporating refurbishment and storage options.
              </p>
              <p>
                Contact us to maximize the value of your surplus materials and
                embark on a sustainable journey in oil industry material
                management services and mining equipment surplus marketplace.
              </p>
            </div>
            <div>
              <a
                className={`${styles.quote} ${styles.btn}`}
                id='marketplace'
                href='/'
                aria-label='marketplace'
              >
                Marketplace <FontAwesomeIcon icon='cart-plus' />
              </a>
            </div>
          </div>
          <div className={`${styles.servicecard} col-lg-6 g-4`}>
            <h5 className='h5'>
              INDUSTRIES <FontAwesomeIcon icon='industry' />
            </h5>
            <div className={styles.desc}>
              <h6 class='h6 text-center m-3'>OIL & GAS</h6>
              <p>
                In the expansive domain of the Oil & Gas sector, our focus spans
                both downhole and surface equipment, ensuring a holistic
                approach to your project needs. From critical components such as
                float shoes and wellheads to the intricate details of completion
                accessories and mud/cement/frac pumps, our offerings cover a
                comprehensive spectrum. Within our ecosystem, consisting of
                operators, service companies, manufacturers, and consultants,
                collaboration is key. Together, we design and install tailored
                solutions, enhancing your project's resource utilization and
                ensuring seamless operations in the Oil & Gas industry.
              </p>
              <h6 class='h6 text-center m-3'>MINING</h6>
              <p>
                Within our Mining services, we delve into spare parts catering
                to both extraction and plant-side operations. Whether it's kits,
                belts, castings, or essential equipment for drilling and
                blasting, our offerings are designed to address the intricacies
                of mining operations. Going beyond mere transactions, we employ
                a meticulous approach, segmenting, refurbishing, and conducting
                comprehensive price surveys before listing your inventory. This
                ensures that when you engage with our services, you not only
                acquire quality equipment but also experience a tangible boost
                in project efficiency within the dynamic landscape of the mining
                industry.
              </p>
              <p>
                Explore the multifaceted benefits of surplus inventory
                management with us, where simplicity meets strategic solutions.
                Engage with our comprehensive services to elevate your project's
                efficiency and success across the Oil & Gas, Mining, and Heavy
                Equipment sectors.
              </p>
              <h6 class='h6 text-center m-3'>SURPLUS INVENTORY</h6>
              <p>
                At BADGERS, we take pride in managing surplus inventory across
                various industries. Whether you are part of the Oil & Gas
                sector, mining operations, or heavy equipment industry, our
                expertise extends to optimizing surplus resources. Through
                meticulous segmentation, refurbishment, and strategic pricing,
                we ensure that your surplus materials find value in our
                dedicated marketplace.
              </p>
            </div>
            <div>
              <a
                className={`${styles.quote} ${styles.btn}`}
                id='marketplace'
                href='/'
                aria-label='marketplace'
              >
                Marketplace <FontAwesomeIcon icon='cart-plus' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
