import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './info.module.css';
import Banner from '../components/banner';

const Privacy = () => {


  return (
    <>
      <Banner />
      <SEO
        title={`BADGERS: Privacy Notice`}
        page='privacy policy'
        description={`BADGERS: privacy policy`}
        name='BADGERS'
        type='Policy'
      />
      <div className={styles.pan}>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Privacy Notice</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Privacy Notice <FontAwesomeIcon icon='eye-slash' />
          </h4>
        </div>

        {/* ------------------------------------------------------------------------------- */}
        <div className='row g-3'>
          <p className='my-2'>
            <strong>Last updated February 20, 2023</strong>
          </p>

          <div className='my-4'>
            <p className='my-2'>
              This privacy notice for Badgers SAS (<strong>"Company," </strong>
              <strong>"we," </strong> <strong>"us," </strong> or
              <strong> "our"</strong>), describes how and why we might collect,
              store, use, and/or share (<strong>"process"</strong>) your
              information when you use our services (<strong>"Services"</strong>
              ), such as when you:
            </p>
            <ul class='list-group'>
              <li class='my-2 list-group-item border-0'>
                • Visit our website at{' '}
                <a href='https://www.bdgrs.com'>https://www.bdgrs.com</a>, or
                any website of ours that links to this privacy notice.
              </li>
              <li class='my-2 list-group-item border-0'>
                • Engage with us in other related ways, including any sales,
                marketing, or events.
              </li>
            </ul>
            <p>
              <strong>Questions or concerns?</strong> Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at info@bdgrs.com.
            </p>
          </div>

          <div className='my-4'>
            <h5 className='h5'>SUMMARY OF KEY POINTS</h5>
            <p className='my-3'>
              <strong>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for. You
                can also click <a href='#toc'>here</a> to go directly to our
                table of contents.
              </strong>
            </p>

            <p className='my-3'>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with Badgers SAS and the
              Services, the choices you make, and the products and features you
              use. Click <a href='#toc1'>here</a> to learn more.
            </p>

            <p className='my-3'>
              <strong>Do we process any sensitive personal information?</strong>{' '}
              We do not process sensitive personal information.
            </p>

            <p className='my-3'>
              <strong>Do we receive any information from third parties?</strong>{' '}
              We do not receive any information from third parties.
            </p>

            <p className='my-3'>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Click{' '}
              <a href='#toc2'>here</a> to learn more.
            </p>

            <p className='my-3'>
              <strong>
                In what situations and with which parties do we share personal
                information?
              </strong>{' '}
              We may share information in specific situations and with specific
              third parties. Click <a href='#toc4'>here</a> to learn more.
            </p>

            <p className='my-3'>
              <strong>How do we keep your information safe?</strong> We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Click <a href='#toc7'>here</a> to learn more.
            </p>

            <p className='my-3'>
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information. Click{' '}
              <a href='#toc9'>here</a> to learn more.
            </p>

            <p className='my-3'>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by filling out our data subject request
              form available here:{' '}
              <a href='https://www.bdgrs.com/user/update'>
                https://www.bdgrs.com/user/update
              </a>
              , or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </p>
          </div>

          <div id='toc' className='my-4'>
            <h5 className='h5'>TABLE OF CONTENTS</h5>
            <ol class='list-group list-group-numbered'>
              <li class='list-group-item border-0'>
                <a href='#toc1'>WHAT INFORMATION DO WE COLLECT?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc2'>HOW DO WE PROCESS YOUR INFORMATION?</a>
              </li>
              <li class='list-group-item border-0 bg-0'>
                <a href='#toc3'>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc4'>
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc5'>
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc6'> HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc7'> HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc8'>DO WE COLLECT INFORMATION FROM MINORS?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc9'>WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc10'>CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc11'>
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc12'>
                  DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc13'>DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc14'>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              </li>
              <li class='list-group-item border-0'>
                <a href='#toc15'>
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </a>
              </li>
            </ol>
          </div>
          <div>
            <ol class='list-group list-group-numbered'>
              <li id='toc1' class='list-group-item border-0'>
                <span className='h5'>WHAT INFORMATION DO WE COLLECT?</span>
                <h6 className='my-3 h6'>
                  Personal information you disclose to us
                </h6>
                <p className='my-3'>
                  <strong>In Short:</strong> We collect personal information
                  that you provide to us
                </p>
                <p className='my-3'>
                  We collect personal information that you voluntarily provide
                  to us when you register on the Services, express an interest
                  in obtaining information about us or our products and
                  Services, when you participate in activities on the Services,
                  or otherwise when you contact us.
                </p>
                <p className='my-3'>
                  <strong>Personal Information Provided by You:</strong> The
                  personal information that we collect depends on the context of
                  your interactions with us and the Services, the choices you
                  make, and the products and features you use. The personal
                  information we collect may include the following:
                </p>
                <ul className='list-group'>
                  <li className='list-group-item border-0'>• names</li>
                  <li className='list-group-item border-0'>• phone numbers</li>
                  <li className='list-group-item border-0'>
                    • email addresses
                  </li>
                  <li className='list-group-item border-0'>
                    • mailing addresses
                  </li>
                  <li className='list-group-item border-0'>• job titles</li>
                  <li className='list-group-item border-0'>• passwords</li>
                  <li className='list-group-item border-0'>
                    • contact or authentication data
                  </li>
                  <li className='list-group-item border-0'>
                    • billing addresses
                  </li>
                  <li className='list-group-item border-0'>• user names</li>
                  <li className='list-group-item border-0'>• company names</li>
                  <li className='list-group-item border-0'>• city or state</li>
                  <li className='list-group-item border-0'>• country</li>
                </ul>

                <p className='my-3'>
                  <strong>Sensitive Information :</strong>We do not process
                  sensitive information.
                </p>
                <p className='my-3'>
                  All personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information.
                </p>
                <h5 className='h5'>Information automatically collected</h5>
                <p className='my-3'>
                  <strong>In Short: </strong>Some information — such as your
                  Internet Protocol (IP) address and/or browser and device
                  characteristics — is collected automatically when you visit
                  our Services.
                </p>
                <p className='my-3'>
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes.
                </p>
                <p className='my-3'>
                  Like many businesses, we also collect information through
                  cookies and similar technologies.
                </p>
                <p className='my-3'>The information we collect includes:</p>

                <ul className='list-group'>
                  <li className='list-group-item border-0'>
                    • Log and Usage Data. Log and usage data is service-related,
                    diagnostic, usage, and performance information our servers
                    automatically collect when you access or use our Services
                    and which we record in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type, and settings and
                    information about your activity in the Services (such as the
                    date/time stamps associated with your usage, pages and files
                    viewed, searches, and other actions you take such as which
                    features you use), device event information (such as system
                    activity, error reports (sometimes called "crash dumps"),
                    and hardware settings).
                  </li>
                  <li className='list-group-item border-0'>
                    • Device Data. We collect device data such as information
                    about your computer, phone, tablet, or other device you use
                    to access the Services. Depending on the device used, this
                    device data may include information such as your IP address
                    (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet
                    service provider and/or mobile carrier, operating system,
                    and system configuration information.
                  </li>
                  <li className='list-group-item border-0'>
                    • Location Data. We collect location data such as
                    information about your device's location, which can be
                    either precise or imprecise. How much information we collect
                    depends on the type and settings of the device you use to
                    access the Services. For example, we may use GPS and other
                    technologies to collect geolocation data that tells us your
                    current location (based on your IP address). You can opt out
                    of allowing us to collect this information either by
                    refusing access to the information or by disabling your
                    Location setting on your device. However, if you choose to
                    opt out, you may not be able to use certain aspects of the
                    Services.
                  </li>
                </ul>
              </li>
              <li id='toc2' class='list-group-item border-0 my-3'>
                <span className='h5'>HOW DO WE PROCESS YOUR INFORMATION?</span>
                <p className='my-3'>
                  <strong>In Short: </strong>We process your information to
                  provide, improve, and administer our Services, communicate
                  with you, for security and fraud prevention, and to comply
                  with law. We may also process your information for other
                  purposes with your consent.
                </p>
                <p className='my-3'>
                  <strong>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </strong>
                </p>
                <p className='my-3'>
                  <strong>
                    • To facilitate account creation and authentication and
                    otherwise manage user accounts.
                  </strong>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                </p>
                <p className='my-3'>
                  <strong>
                    • To deliver and facilitate delivery of services to the
                    user.
                  </strong>
                  We may process your information to provide you with the
                  requested service.
                </p>
                <p className='my-3'>
                  <strong>
                    • To respond to user inquiries/offer support to users.{' '}
                  </strong>
                  We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.
                </p>
                <p className='my-3'>
                  <strong>• To send administrative information to you. </strong>
                  We may process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                </p>
                <p className='my-3'>
                  <strong>• To fulfill and manage your orders. </strong>We may
                  process your information to fulfill and manage your orders,
                  payments, returns, and exchanges made through the Services.
                </p>
                <p className='my-3'>
                  <strong>• To enable user-to-user communications. </strong>We
                  may process your information if you choose to use any of our
                  offerings that allow for communication with another user.
                </p>
                <p className='my-3'>
                  <strong>• To request feedback. </strong>We may process your
                  information when necessary to request feedback and to contact
                  you about your use of our Services.
                </p>
                <p className='my-3'>
                  <strong>
                    • To send you marketing and promotional communications.{' '}
                  </strong>
                  We may process the personal information you send to us for our
                  marketing purposes, if this is in accordance with your
                  marketing preferences. You can opt out of our marketing emails
                  at any time.
                </p>
                <p className='my-3'>
                  <strong>• To deliver targeted advertising to you. </strong>We
                  may process your information to develop and display
                  personalized content and advertising tailored to your
                  interests, location, and more.
                </p>
                <p className='my-3'>
                  <strong>• To protect our Services. </strong>We may process
                  your information as part of our efforts to keep our Services
                  safe and secure, including fraud monitoring and prevention.
                </p>
                <p className='my-3'>
                  <strong>• To identify usage trends. </strong>We may process
                  information about how you use our Services to better
                  understand how they are being used so we can improve them.
                </p>
                <p className='my-3'>
                  <strong>
                    • To determine the effectiveness of our marketing and
                    promotional campaigns.{' '}
                  </strong>
                  We may process your information to better understand how to
                  provide marketing and promotional campaigns that are most
                  relevant to you.
                </p>
                <p className='my-3'>
                  <strong>
                    • To save or protect an individual's vital interest.{' '}
                  </strong>
                  We may process your information when necessary to save or
                  protect an individual’s vital interest, such as to prevent
                  harm.
                </p>
              </li>
              <li id='toc3' class='list-group-item border-0 bg-0 my-3'>
                <span className='h5'>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>We only process your personal
                  information when we believe it is necessary and we have a
                  valid legal reason (i.e., legal basis) to do so under
                  applicable law, like with your consent, to comply with laws,
                  to provide you with services to enter into or fulfill our
                  contractual obligations, to protect your rights, or to fulfill
                  our legitimate business interests.
                </p>
                <p className='my-3'>
                  <strong>
                    <u>
                      If you are located in the EU or UK, this section applies
                      to you.
                    </u>
                  </strong>
                </p>
                <p className='my-3'>
                  The General Data Protection Regulation (GDPR) and UK GDPR
                  require us to explain the valid legal bases we rely on in
                  order to process your personal information. As such, we may
                  rely on the following legal bases to process your personal
                  information:
                </p>
                <p className='my-3'>
                  <strong> • Consent.</strong>We may process your information if
                  you have given us permission (i.e., consent) to use your
                  personal information for a specific purpose. You can withdraw
                  your consent at any time.
                </p>
                <p className='my-3'>
                  <strong> • Performance of a Contract.</strong>We may process
                  your personal information when we believe it is necessary to
                  fulfill our contractual obligations to you, including
                  providing our Services or at your request prior to entering
                  into a contract with you.
                </p>
                <p className='my-3'>
                  <strong> • Legitimate Interests.</strong>We may process your
                  information when we believe it is reasonably necessary to
                  achieve our legitimate business interests and those interests
                  do not outweigh your interests and fundamental rights and
                  freedoms. For example, we may process your personal
                  information for some of the purposes described in order to:
                </p>
                <p>
                  <ul className='list-group'>
                    <li class='list-group-item border-0 my-1'>
                      • Send users information about special offers and
                      discounts on our products and services
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • Develop and display personalized and relevant
                      advertising content for our users
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • Analyze how our Services are used so we can improve them
                      to engage and retain users
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • Support our marketing activities
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • Diagnose problems and/or prevent fraudulent activities
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • Understand how our users use our products and services
                      so we can improve user experience
                    </li>
                  </ul>
                  <p className='my-3'>
                    <strong>• Legal Obligations. </strong>We may process your
                    information where we believe it is necessary for compliance
                    with our legal obligations, such as to cooperate with a law
                    enforcement body or regulatory agency, exercise or defend
                    our legal rights, or disclose your information as evidence
                    in litigation in which we are involved.
                  </p>
                  <p className='my-3'>
                    <strong>• Vital Interests. </strong>We may process your
                    information where we believe it is necessary to protect your
                    vital interests or the vital interests of a third party,
                    such as situations involving potential threats to the safety
                    of any person.
                  </p>
                </p>

                <p className='my-5'>
                  <strong>
                    <u>
                      If you are located in Canada, this section applies to you.
                    </u>
                  </strong>
                </p>
                <p className='my-3'>
                  We may process your information if you have given us specific
                  permission (i.e., express consent) to use your personal
                  information for a specific purpose, or in situations where
                  your permission can be inferred (i.e., implied consent). You
                  can withdraw your consent at any time. Click here to learn
                  more.
                </p>
                <p className='my-3'>
                  In some exceptional cases, we may be legally permitted under
                  applicable law to process your information without your
                  consent, including, for example:
                </p>
                <p>
                  <ul className='list-group'>
                    <li class='list-group-item border-0 my-1'>
                      • If collection is clearly in the interests of an
                      individual and consent cannot be obtained in a timely way
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • For investigations and fraud detection and prevention
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • For business transactions provided certain conditions
                      are met
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If it is contained in a witness statement and the
                      collection is necessary to assess, process, or settle an
                      insurance claim
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • For identifying injured, ill, or deceased persons and
                      communicating with next of kin
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If we have reasonable grounds to believe an individual
                      has been, is, or may be victim of financial abuse
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If it is reasonable to expect collection and use with
                      consent would compromise the availability or the accuracy
                      of the information and the collection is reasonable for
                      purposes related to investigating a breach of an agreement
                      or a contravention of the laws of Canada or a province
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If disclosure is required to comply with a subpoena,
                      warrant, court order, or rules of the court relating to
                      the production of records
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If it was produced by an individual in the course of
                      their employment, business, or profession and the
                      collection is consistent with the purposes for which the
                      information was produced
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If the collection is solely for journalistic, artistic,
                      or literary purposes
                    </li>
                    <li class='list-group-item border-0 my-1'>
                      • If the information is publicly available and is
                      specified by the regulations
                    </li>
                  </ul>
                </p>
              </li>
              <li id='toc4' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>We may share information in
                  specific situations described in this section and/or with the
                  following third parties.
                </p>
                <p className='my-3'>
                  We may need to share your personal information in the
                  following situations:
                </p>
                <p className='my-3 mx-3'>
                  <strong>• Business Transfers. </strong>
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </p>
              </li>
              <li id='toc5' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>We may use cookies and other
                  tracking technologies to collect and store your information.
                </p>
                <p className='my-3'>
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information. Specific
                  information about how we use such technologies and how you can
                  refuse certain cookies is set out in our Cookie Notice.
                </p>
              </li>
              <li id='toc6' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>We keep your information for as
                  long as necessary to fulfill the purposes outlined in this
                  privacy notice unless otherwise required by law.
                </p>
                <p className='my-3'>
                  We will only keep your personal information for as long as it
                  is necessary for the purposes set out in this privacy notice,
                  unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements). No
                  purpose in this notice will require us keeping your personal
                  information for longer than the period of time in which users
                  have an account with us.
                </p>
                <p className='my-3'>
                  When we have no ongoing legitimate business need to process
                  your personal information, we will either delete or anonymize
                  such information, or, if this is not possible (for example,
                  because your personal information has been stored in backup
                  archives), then we will securely store your personal
                  information and isolate it from any further processing until
                  deletion is possible.
                </p>
              </li>
              <li id='toc7' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>We aim to protect your personal
                  information through a system of organizational and technical
                  security measures.
                </p>
                <p className='my-3'>
                  We have implemented appropriate and reasonable technical and
                  organizational security measures designed to protect the
                  security of any personal information we process. However,
                  despite our safeguards and efforts to secure your information,
                  no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorized third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                </p>
              </li>
              <li id='toc8' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  DO WE COLLECT INFORMATION FROM MINORS?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>We do not knowingly collect data
                  from or market to children under 18 years of age.
                </p>
                <p className='my-3'>
                  We do not knowingly solicit data from or market to children
                  under 18 years of age. By using the Services, you represent
                  that you are at least 18 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent’s
                  use of the Services. If we learn that personal information
                  from users less than 18 years of age has been collected, we
                  will deactivate the account and take reasonable measures to
                  promptly delete such data from our records. If you become
                  aware of any data we may have collected from children under
                  age 18, please contact us at info@bdgrs.com.
                </p>
              </li>
              <li id='toc9' class='list-group-item border-0 my-3'>
                <span className='h5'>WHAT ARE YOUR PRIVACY RIGHTS?</span>
                <p className='my-3'>
                  <strong>In Short: </strong>In some regions, such as the
                  European Economic Area (EEA), United Kingdom (UK), and Canada,
                  you have rights that allow you greater access to and control
                  over your personal information. You may review, change, or
                  terminate your account at any time.
                </p>
                <p className='my-3'>
                  In some regions (like the EEA, UK, and Canada), you have
                  certain rights under applicable data protection laws. These
                  may include the right (i) to request access and obtain a copy
                  of your personal information, (ii) to request rectification or
                  erasure; (iii) to restrict the processing of your personal
                  information; and (iv) if applicable, to data portability. In
                  certain circumstances, you may also have the right to object
                  to the processing of your personal information. You can make
                  such a request by contacting us by using the contact details
                  provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
                  NOTICE?" below.
                </p>
                <p className='my-3'>
                  We will consider and act upon any request in accordance with
                  applicable data protection laws
                </p>
                <p className='my-3'>
                  If you are located in the EEA or UK and you believe we are
                  unlawfully processing your personal information, you also have
                  the right to complain to your local data protection
                  supervisory authority. You can find their contact details
                  here:{' '}
                  <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                  </a>
                  .
                </p>
                <p className='my-3'>
                  If you are located in Switzerland, the contact details for the
                  data protection authorities are available here:
                  <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                  </a>
                  .
                </p>
                <p className='my-3'>
                  <strong>
                    <u>Withdrawing your consent:</u>
                  </strong>{' '}
                  If we are relying on your consent to process your personal
                  information, which may be express and/or implied consent
                  depending on the applicable law, you have the right to
                  withdraw your consent at any time. You can withdraw your
                  consent at any time by contacting us by using the contact
                  details provided in the section "HOW CAN YOU CONTACT US ABOUT
                  THIS NOTICE?" below.
                </p>
                <p className='my-3'>
                  However, please note that this will not affect the lawfulness
                  of the processing before its withdrawal nor, when applicable
                  law allows, will it affect the processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent.
                </p>
                <p className='my-3'>
                  <strong>
                    <u>
                      Opting out of marketing and promotional communications:{' '}
                    </u>
                  </strong>
                  You can unsubscribe from our marketing and promotional
                  communications at any time by clicking on the unsubscribe link
                  in the emails that we send, or by contacting us using the
                  details provided in the section "HOW CAN YOU CONTACT US ABOUT
                  THIS NOTICE?" below. You will then be removed from the
                  marketing lists. However, we may still communicate with you —
                  for example, to send you service-related messages that are
                  necessary for the administration and use of your account, to
                  respond to service requests, or for other non-marketing
                  purposes.
                </p>
                <p className='h6 my-3'>Account Information</p>
                <p className='my-3'>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                </p>
                <ul className='list-group'>
                  <li className='list-group-item border-0 my-2'>
                    • Log in to your account settings and update your user
                    account.
                  </li>
                  <li className='list-group-item border-0 my-2'>
                    • Contact us using the contact information provided.
                  </li>
                  <li className='list-group-item border-0 my-2'>
                    {' '}
                    • Contact us by email.
                  </li>
                </ul>
                <p className='my-3'>
                  Upon your request to terminate your account, we will
                  deactivate or delete your account and information from our
                  active databases. However, we may retain some information in
                  our files to prevent fraud, troubleshoot problems, assist with
                  any investigations, enforce our legal terms and/or comply with
                  applicable legal requirements.
                </p>
                <p className='my-3'>
                  <strong>
                    <u>Cookies and similar technologies: </u>
                  </strong>
                  Most Web browsers are set to accept cookies by default. If you
                  prefer, you can usually choose to set your browser to remove
                  cookies and to reject cookies. If you choose to remove cookies
                  or reject cookies, this could affect certain features or
                  services of our Services. To opt out of interest-based
                  advertising by advertisers on our Services visit{' '}
                  <a href='http://www.aboutads.info/choices/'>
                    http://www.aboutads.info/choices
                  </a>
                  .
                </p>
                <p className='my-3'>
                  If you have questions or comments about your privacy rights,
                  you may email us at{' '}
                  <a href='mailto:info@bdgrs.com'>info@bdgrs.com</a>.
                </p>
              </li>
              <li id='toc10' class='list-group-item border-0 my-3'>
                <span className='h5'>CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                <p className='my-3'>
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track ("DNT") feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  privacy notice.
                </p>
              </li>
              <li id='toc11' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>Yes, if you are a resident of
                  California, you are granted specific rights regarding access
                  to your personal information.
                </p>
                <p className='my-3'>
                  California Civil Code Section 1798.83, also known as the
                  "Shine The Light" law, permits our users who are California
                  residents to request and obtain from us, once a year and free
                  of charge, information about categories of personal
                  information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third
                  parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California
                  resident and would like to make such a request, please submit
                  your request in writing to us using the contact information
                  provided below.
                </p>
                <p className='my-3'>
                  If you are under 18 years of age, reside in California, and
                  have a registered account with Services, you have the right to
                  request removal of unwanted data that you publicly post on the
                  Services. To request removal of such data, please contact us
                  using the contact information provided below and include the
                  email address associated with your account and a statement
                  that you reside in California. We will make sure the data is
                  not publicly displayed on the Services, but please be aware
                  that the data may not be completely or comprehensively removed
                  from all our systems (e.g., backups, etc.).
                </p>
                <h5 className='h6'>CCPA Privacy Notice</h5>
                <p className='my-3'>
                  The California Code of Regulations defines a "resident" as:
                </p>
                <p className='my-0 mx-2'>
                  (1) every individual who is in the State of California for
                  other than a temporary or transitory purpose and
                </p>
                <p className='my-0 mx-2'>
                  (2) every individual who is domiciled in the State of
                  California who is outside the State of California for a
                  temporary or transitory purpose
                </p>
                <p className='my-3'>
                  All other individuals are defined as "non-residents."
                </p>
                <p className='my-3'>
                  If this definition of "resident" applies to you, we must
                  adhere to certain rights and obligations regarding your
                  personal information.
                </p>
                <p className='my-3'>
                  <strong>
                    What categories of personal information do we collect?
                  </strong>
                </p>
                <p className='my-3'>
                  We have collected the following categories of personal
                  information in the past twelve (12) months:
                </p>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th scope='col'>Category</th>
                      <th scope='col'>Examples</th>
                      <th scope='col'>Collected</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr scope='row'>
                      <td>A. Identifiers</td>
                      <td>
                        Contact details, such as real name, alias, postal
                        address, telephone or mobile contact number, unique
                        personal identifier, online identifier, Internet
                        Protocol address, email address, and account name
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>
                        B. Personal information categories listed in the
                        California Customer Records statute
                      </td>
                      <td>
                        Name, contact information, education, employment,
                        employment history, and financial information
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>
                        C. Protected classification characteristics under
                        California or federal law
                      </td>
                      <td>Gender and date of birth</td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>D. Commercial information</td>
                      <td>
                        Transaction information, purchase history, financial
                        details, and payment information
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>E. Biometric information</td>
                      <td>Fingerprints and voiceprints</td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>F. Internet or other similar network activity</td>
                      <td>
                        Browsing history, search history, online behavior,
                        interest data, and interactions with our and other
                        websites, applications, systems, and advertisements
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>G. Geolocation data</td>
                      <td>Device location</td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>
                        H. Audio, electronic, visual, thermal, olfactory, or
                        similar information
                      </td>
                      <td>
                        Images and audio, video or call recordings created in
                        connection with our business activities
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>I. Professional or employment-related information</td>
                      <td>
                        Business contact details in order to provide you our
                        Services at a business level or job title, work history,
                        and professional qualifications if you apply for a job
                        with us
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>J. Education Information</td>
                      <td>Student records and directory information</td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>
                        K. Inferences drawn from other personal information
                      </td>
                      <td>
                        Inferences drawn from any of the collected personal
                        information listed above to create a profile or summary
                        about, for example, an individual’s preferences and
                        characteristics
                      </td>
                      <td>No</td>
                    </tr>
                    <tr scope='row'>
                      <td>L. Sensitive Personal Information</td>
                      <td>Health records etc</td>
                      <td>No</td>
                    </tr>
                  </tbody>
                </table>
                <p className='my-3'>
                  We may also collect other personal information outside of
                  these categories through instances where you interact with us
                  in person, online, or by phone or mail in the context of:
                </p>
                <ul className='list-group my-3'>
                  <li className='list-group-item border-0 my-1'>
                    • Receiving help through our customer support channels;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Participation in customer surveys or contests; and
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Facilitation in the delivery of our Services and to
                    respond to your inquiries.
                  </li>
                </ul>
                <p className='my-3'>
                  <strong>
                    How do we use and share your personal information?
                  </strong>
                </p>
                <p className='my-3'>
                  More information about our data collection and sharing
                  practices can be found in this privacy notice.
                </p>
                <p className='my-3'>
                  You may contact us by email at{' '}
                  <a href='mailto:info@bdgrs.com'>info@bdgrs.com</a>, by
                  visiting{' '}
                  <a href='https://www.bdgrs.com/contact'>
                    https://www.bdgrs.com/contact
                  </a>
                  , by mailing to: Badgers SAS 12 avenue des Prés 78180
                  Montigny-le-Bretonneux France, or by referring to the contact
                  details at the bottom of this document.
                </p>
                <p className='my-3'>
                  If you are using an authorized agent to exercise your right to
                  opt out we may deny a request if the authorized agent does not
                  submit proof that they have been validly authorized to act on
                  your behalf.
                </p>
                <p className='my-3'>
                  <strong>
                    Will your information be shared with anyone else?
                  </strong>
                </p>
                <p className='my-3'>
                  We may disclose your personal information with our service
                  providers pursuant to a written contract between us and each
                  service provider. Each service provider is a for-profit entity
                  that processes the information on our behalf, following the
                  same strict privacy protection obligations mandated by the
                  CCPA.
                </p>
                <p className='my-3'>
                  We may use your personal information for our own business
                  purposes, such as for undertaking internal research for
                  technological development and demonstration. This is not
                  considered to be "selling" of your personal information.
                </p>
                <p className='my-3'>
                  Badgers SAS has not disclosed, sold, or shared any personal
                  information to third parties for a business or commercial
                  purpose in the preceding twelve (12) months. Badgers SAS will
                  not sell or share personal information in the future belonging
                  to website visitors, users, and other consumers.
                </p>
                <p className='my-3'>
                  <strong>
                    Your rights with respect to your personal data
                  </strong>
                </p>
                <p className='my-3'>
                  <u>
                    Right to request deletion of the data — Request to delete
                  </u>
                </p>
                <p className='my-3'>
                  You can ask for the deletion of your personal information. If
                  you ask us to delete your personal information, we will
                  respect your request and delete your personal information,
                  subject to certain exceptions provided by law, such as (but
                  not limited to) the exercise by another consumer of his or her
                  right to free speech, our compliance requirements resulting
                  from a legal obligation, or any processing that may be
                  required to protect against illegal activities.
                </p>
                <p className='my-3'>
                  <u>Right to be informed — Request to know</u>
                </p>
                <p className='my-3'>
                  Depending on the circumstances, you have a right to know:
                </p>
                <ul className='list-group my-3'>
                  <li className='list-group-item border-0 my-1'>
                    • whether we collect and use your personal information;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • the categories of personal information that we collect;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • the purposes for which the collected personal information
                    is used;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • whether we sell or share personal information to third
                    parties;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • the categories of personal information that we sold,
                    shared, or disclosed for a business purpose;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • the categories of third parties to whom the personal
                    information was sold, shared, or disclosed for a business
                    purpose;
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • the business or commercial purpose for collecting,
                    selling, or sharing personal information; and
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • the specific pieces of personal information we collected
                    about you.
                  </li>
                </ul>
                <p className='my-3'>
                  In accordance with applicable law, we are not obligated to
                  provide or delete consumer information that is de-identified
                  in response to a consumer request or to re-identify individual
                  data to verify a consumer request.
                </p>
                <p className='my-3'>
                  <u>
                    Right to Non-Discrimination for the Exercise of a Consumer’s
                    Privacy Rights
                  </u>
                </p>
                <p className='my-3'>
                  We will not discriminate against you if you exercise your
                  privacy rights.
                </p>
                <p className='my-3'>
                  <u>
                    Right to Limit Use and Disclosure of Sensitive Personal
                    Information
                  </u>
                </p>
                <p className='my-3'>
                  We do not process consumer's sensitive personal information.
                </p>
                <p className='my-3'>
                  <u>Verification process</u>
                </p>
                <p className='my-3'>
                  Upon receiving your request, we will need to verify your
                  identity to determine you are the same person about whom we
                  have the information in our system. These verification efforts
                  require us to ask you to provide information so that we can
                  match it with information you have previously provided us. For
                  instance, depending on the type of request you submit, we may
                  ask you to provide certain information so that we can match
                  the information you provide with the information we already
                  have on file, or we may contact you through a communication
                  method (e.g., phone or email) that you have previously
                  provided to us. We may also use other verification methods as
                  the circumstances dictate.
                </p>
                <p className='my-3'>
                  We will only use personal information provided in your request
                  to verify your identity or authority to make the request. To
                  the extent possible, we will avoid requesting additional
                  information from you for the purposes of verification.
                  However, if we cannot verify your identity from the
                  information already maintained by us, we may request that you
                  provide additional information for the purposes of verifying
                  your identity and for security or fraud-prevention purposes.
                  We will delete such additionally provided information as soon
                  as we finish verifying you.
                </p>
                <p className='my-3'>
                  <u>Other privacy rights</u>
                </p>
                <ul className='list-group my-3'>
                  <li className='list-group-item border-0 my-1'>
                    • You may object to the processing of your personal
                    information.
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • You may request correction of your personal data if it is
                    incorrect or no longer relevant, or ask to restrict the
                    processing of the information.
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • You can designate an authorized agent to make a request
                    under the CCPA on your behalf. We may deny a request from an
                    authorized agent that does not submit proof that they have
                    been validly authorized to act on your behalf in accordance
                    with the CCPA.
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • You may request to opt out from future selling or sharing
                    of your personal information to third parties. Upon
                    receiving an opt-out request, we will act upon the request
                    as soon as feasibly possible, but no later than fifteen (15)
                    days from the date of the request submission.
                  </li>
                </ul>
                <p className='my-3'>
                  To exercise these rights, you can contact us by email at
                  <a href='mailto:info@bdgrs.com'> info@bdgrs.com</a>, by
                  visiting{' '}
                  <a href='https://www.bdgrs.com/contact'>
                    https://www.bdgrs.com/contact
                  </a>
                  , by mailing to: Badgers SAS 12 avenue des Prés 78180
                  Montigny-le-Bretonneux France, or by referring to the contact
                  details at the bottom of this document. If you have a
                  complaint about how we handle your data, we would like to hear
                  from you.
                </p>
              </li>
              <li id='toc12' class='list-group-item border-0 my-3'>
                <span className='h5'>
                  DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>Yes, if you are a resident of
                  Virginia, you may be granted specific rights regarding access
                  to and use of your personal information.
                </p>
                <h6 className='h6 my-3'>Virginia CDPA Privacy Notice</h6>
                <p className='my-3'>
                  Under the Virginia Consumer Data Protection Act (CDPA):
                </p>
                <p className='my-3'>
                  "Consumer" means a natural person who is a resident of the
                  Commonwealth acting only in an individual or household
                  context. It does not include a natural person acting in a
                  commercial or employment context.
                </p>
                <p className='my-3'>
                  "Personal data" means any information that is linked or
                  reasonably linkable to an identified or identifiable natural
                  person. "Personal data" does not include de-identified data or
                  publicly available information.
                </p>
                <p className='my-3'>
                  "Sale of personal data" means the exchange of personal data
                  for monetary consideration.
                </p>
                <p className='my-3'>
                  If this definition "consumer" applies to you, we must adhere
                  to certain rights and obligations regarding your personal
                  data.
                </p>
                <p className='my-3'>
                  The information we collect, use, and disclose about you will
                  vary depending on how you interact with Badgers SAS and our
                  Services. To find out more, please visit the following links:
                </p>
                <ul className='list-group my-3'>
                  <li className='list-group-item border-0 my-1'>
                    • Personal data we collect
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • How we use your personal data
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • When and with whom we share your personal data
                  </li>
                </ul>
                <p className='my-3'>
                  <u>Your rights with respect to your personal data</u>
                </p>
                <ul className='list-group my-3'>
                  <li className='list-group-item border-0 my-1'>
                    • Right to be informed whether or not we are processing your
                    personal data
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Right to access your personal data
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Right to correct inaccuracies in your personal data
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Right to request deletion of your personal data
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Right to obtain a copy of the personal data you previously
                    shared with us
                  </li>
                  <li className='list-group-item border-0 my-1'>
                    • Right to opt out of the processing of your personal data
                    if it is used for targeted advertising, the sale of personal
                    data, or profiling in furtherance of decisions that produce
                    legal or similarly significant effects ("profiling")
                  </li>
                </ul>
                <p className='my-3'>
                  Badgers SAS has not sold any personal data to third parties
                  for business or commercial purposes. Badgers SAS will not sell
                  personal data in the future belonging to website visitors,
                  users, and other consumers.
                </p>
                <p className='my-3'>
                  <u>Exercise your rights provided under the Virginia CDPA</u>
                </p>
                <p className='my-3'>
                  More information about our data collection and sharing
                  practices can be found in this privacy notice.
                </p>
                <p className='my-3'>
                  You may contact us by email at{' '}
                  <a href='mailto:info@bdgrs.com'>info@bdgrs.com</a>, by
                  visiting{' '}
                  <a href='https://www.bdgrs.com/user/update'>
                    https://www.bdgrs.com/user/update
                  </a>
                  , or by referring to the contact details at the bottom of this
                  document.
                </p>
                <p className='my-3'>
                  If you are using an authorized agent to exercise your rights,
                  we may deny a request if the authorized agent does not submit
                  proof that they have been validly authorized to act on your
                  behalf.
                </p>
                <p className='my-3'>
                  <u>Verification process</u>
                </p>
                <p className='my-3'>
                  We may request that you provide additional information
                  reasonably necessary to verify you and your consumer's
                  request. If you submit the request through an authorized
                  agent, we may need to collect additional information to verify
                  your identity before processing your request.
                </p>
                <p className='my-3'>
                  Upon receiving your request, we will respond without undue
                  delay, but in all cases, within forty-five (45) days of
                  receipt. The response period may be extended once by
                  forty-five (45) additional days when reasonably necessary. We
                  will inform you of any such extension within the initial
                  45-day response period, together with the reason for the
                  extension.
                </p>
                <p className='my-3'>
                  <u>Right to appeal</u>
                </p>
                <p className='my-3'>
                  If we decline to take action regarding your request, we will
                  inform you of our decision and reasoning behind it. If you
                  wish to appeal our decision, please email us at
                  <a href='mailto:info@bdgrs.com'> info@bdgrs.com</a>. Within
                  sixty (60) days of receipt of an appeal, we will inform you in
                  writing of any action taken or not taken in response to the
                  appeal, including a written explanation of the reasons for the
                  decisions. If your appeal if denied, you may contact the
                  Attorney General to{' '}
                  <a href='https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint'>
                    submit a complaint.
                  </a>
                </p>
              </li>
              <li id='toc13' class='list-group-item border-0 my-3'>
                <span className='h5 my-3'>
                  DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
                <p className='my-3'>
                  <strong>In Short: </strong>Yes, we will update this notice as
                  necessary to stay compliant with relevant laws.
                </p>
                <p className='my-3'>
                  We may update this privacy notice from time to time. The
                  updated version will be indicated by an updated "Revised" date
                  and the updated version will be effective as soon as it is
                  accessible. If we make material changes to this privacy
                  notice, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a
                  notification. We encourage you to review this privacy notice
                  frequently to be informed of how we are protecting your
                  information.
                </p>
              </li>
              <li id='toc14' class='list-group-item border-0 my-3'>
                <span className='h5 my-3'>
                  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
                <p className='my-3'>
                  If you have questions or comments about this notice, you may
                  email us at <a href='mailto:info@bdgrs.com'>info@bdgrs.com</a>{' '}
                  or by post to:
                </p>
                <p className='my-3'>
                  Badgers SAS <br />
                  12 avenue des Prés <br />
                  Montigny-le-Bretonneux, Yvelines 78180 <br />
                  France
                </p>
              </li>
              <li id='toc15' class='list-group-item border-0 my-3'>
                <span className='h5 my-3'>
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                  YOU?
                </span>
                <p className='my-3'>
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it. To request to
                  review, update, or delete your personal information, please
                  visit:{' '}
                  <a href='https://www.bdgrs.com/user/update'>
                    https://www.bdgrs.com/user/update.
                  </a>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------- */}
    </>
  );
}

export default Privacy