import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import SEO from '../components/seo';
import { useNavigate, Link, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import styles from './signup.module.css';


const EmailVerif = () => {
  // Initial state and variables ---------------------------------------------------\\

  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const [feedback, setFeedback] = useState('');
  const navigate = useNavigate();
  const { token } = useParams();

  const url = `${process.env.REACT_APP_API}/auth`;


  // UseEffects, check if there's valid verification token ---------------------------------------------------\\

  useEffect(() => {
    let active = true;
    (async () => {
      if (!token) {
        setFeedback('No reset token found. Please check your reset link.');
        setLoading(false);
      } else {
        axios
          .get(`${url}/emailverif/${token}`)
          .then((response) => {
            if (response.status === 200) {
                if (active) {
                       toast.success(response.data.message, { duration: 2500 });
                       setFeedback(response.data.message);
                       setTimeout(() => navigate('/user/login', { state: { goTo: '/' }}), 3000);
                       setLoading(false);
                }
            }
          })
          .catch((err) => {
            if (active) {
                    toast.error(err?.response?.data?.error, { duration: 2500 });
                    setFeedback(err?.response?.data?.error);
                    setTimeout(() => navigate('/user/resendverif'), 3000);
                    setLoading(false);
            }
          });
      }
    })();
    return () => {
      setFeedback(null);
    };
  }, [token]);





  // Rendered page ---------------------------------------------------\\
  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Verify your email`}
        page='verify your email'
        description={`BADGERS:  Verify your email`}
        name='BADGERS'
        type='User'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <div className={styles.pan}>
      <SEO
        title={`BADGERS: Verify your email`}
        page='verify your email'
        description={`BADGERS:  Verify your email`}
        name='BADGERS'
        type='User'
      />
      <Toaster position='top-center' reverseOrder={false} />
      <div className=''>
        <Link className='page-links' to='/'>
          <FontAwesomeIcon icon='home' />{' '}
        </Link>
        <span className='link-span'>
          <FontAwesomeIcon icon='angle-right' />
        </span>
        <span className='link-span'>Email Verification</span>
      </div>
      <div className={styles.feedback}>
        <p>{feedback}</p>
      </div>
    </div>
  ); 
};

export default EmailVerif;
