import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { truncate } from '../utils/utils';
import styles from './featured.module.css';
import convertEntities from '../utils/convertEntities';

const FeaturedProduct = ({ product }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/product/${product.id}`)}
      className={styles.productcard}
      key={product?.id.toString()}
    >
      <div className={styles.productimg}>
        <img
          className={styles.productimg}
          src={product.images[0]?.lienImage}
          alt=''
        />
      </div>

      <div className={styles.productdetails}>
        <div className={styles.productdetailspan}>
          <p
            className={`${styles.producttitle} text-wrap`}
            key={product.id}
            dangerouslySetInnerHTML={{ __html: product.title }}
          />

          <p className={styles.price}>
            {product.currency === 'usd' ? '$ ' : '€ '}
            {Number(product.price).toLocaleString('en')}
          </p>
          <p className={styles.subheads}>{product.brand}</p>
          <p className={styles.subheads}>{product.type}</p>
        </div>
        <div className={styles.productlocspan}>
          <p className={styles.subheads}>
            <FontAwesomeIcon name='remove from favourites' icon='globe' />{' '}
            {product.region}
          </p>
          <p className={styles.subheads}>
            <FontAwesomeIcon name='remove from favourites' icon='fan' />{' '}
            {product.condition}
          </p>
        </div>
      </div>
      <div className={styles.desc}>
        <p className={styles.paragraph}>{convertEntities(truncate(product.description, 200))}</p>
      </div>
    </div>
  );
};

export default FeaturedProduct;
