import ParseJwt from '../tokenComponent';
import { logoutSuccess } from '../redux/user';

const isLoggedIn = (navigate, dispatch, goTo = '/') => {
  const token = sessionStorage.getItem('token');
  const user = JSON.parse(sessionStorage.getItem('user'));
  if (!token) return navigate('/user/login', { state: { goTo } });
  if (token && user) {
    const decodedJwt = ParseJwt(token);
    if (decodedJwt.exp * 1000 <= Date.now()) {
      dispatch(logoutSuccess());
      return navigate('/user/login', { state: { goTo } });
    }
  }
};

export default isLoggedIn;
