import React from 'react'
import styles from './order.module.css'
import Piece from './piece';
import { orderStatus } from '../../utils/orderStatus';

const Order = ({order}) => {
    const {id, orderDate, statut, payment, articles} = order;
  return (
    <div className={`${styles.card} my-4`}>
      <div className='d-flex justify-content-between mb-3 flex-wrap'>
        <div className='m-2'>
          <strong>ORDER N°:</strong> <span>{id}</span>
        </div>
        <div className='m-2'>
          <strong>
            <div>ORDERED ON</div>
          </strong>
          <div>{orderDate.split('T')[0]}</div>
        </div>
        <div className='m-2'>
          <strong>
            <div>SHIPPING</div>
          </strong>
          <div>
            {payment[0].shipping} {payment[0].currency === 'usd' ? 'US$' : '€'}
          </div>
        </div>
        <div className='m-2'>
          <strong>
            <div>TOTAL</div>
          </strong>
          <div>
            {payment[0].total} {payment[0].currency === 'usd' ? 'US$' : '€'}
          </div>
        </div>
      </div>
      <div>
        <div>
          {articles.length ? (
            <Piece articles={articles} currency={payment[0].currency} />
          ) : (
            'No articles to display.'
          )}
        </div>
        <div className='d-flex mt-3 flex-wrap'>
          <div className='m-3'>
            <strong>
              <div>ORDER STATUS</div>
            </strong>
            <div>{orderStatus(statut)}</div>
          </div>
          <div className='m-3'>
            <strong>TRACK YOUR ORDER:</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order