import { useLocation } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './confirm.module.css';
import FooterProfile from '../components/footer_profile';

const SignupSuccess = () => {
  // Initial state and variables ---------------------------------------------------\\
    const location = useLocation();
    const user = location.state?.user;

  return (
    <>
      <SEO
        title={`BADGERS: Signup succeeded`}
        page='Signup succeeded'
        description={`BADGERS: Signup succeeded`}
        name='BADGERS'
        type='Inscription'
      />
      <div className='container'>
        <div className='row my-6 justify-content-center' action=''>
            
          <div className={`${styles.servicecard} `}>
            {/* <img className={styles.logo} src='/img/logo.png' alt='logo' /> */}
            <img className={styles.envelope} src='/img/mailconfirm.png' alt='logo' />
            <h4 className='h4 my-2'>
              Confirm your e-mail
            </h4>
            <p className={styles.desc}>
              Hi {user ? `${user?.prenom[0].toUpperCase()}${user?.prenom.slice(1)} ${user?.nom.toUpperCase()}` : 'there'}. Welcome to Badgers! Please confirm your e-mail address to get started.
            </p>
            <h5 className='h5'>
              Confirmation e-mail has been sent to:
            </h5>
            <p className={styles.desc}>
              {user?.email}
            </p>
            <p className={styles.desc}>
              Click on the link in the email to confirm your e-mail address.
            </p>
            <hr className={styles.fat}/>
            <span className={styles.ruler}></span>
            <h5 className={`${styles.fat} h5`}>You didn't receive any e-mail from us?</h5>
            <p>It might have landed in the spam/junk folder!</p>
          </div>
        </div>
      </div>
      <FooterProfile />
    </>
  );
};

export default SignupSuccess;
