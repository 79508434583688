import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import styles from './signupcandy.module.css'


const SignupCandy = () => {
  const navigate = useNavigate()


  return (
    <div className={styles.signupcandy}>
      <p className=''>Not a Member Yet?</p>
      <h4 className='h4'>
        Signup <FontAwesomeIcon icon='user-plus' />
      </h4>
      <div>Signup for free to receive a gift</div>
      <p>Post your listing in a few minutes</p>
      <ol>
        <li className={styles.litem}>
          <FontAwesomeIcon icon='check' className={styles.tick} />
          No credit card required
        </li>
        <li className={styles.litem}>
          <div>
            <FontAwesomeIcon icon='check' className={styles.tick} />
          </div>
          <div> Bookmark your favourite listings</div>
        </li>
        <li className={styles.litem}>
          {' '}
          <FontAwesomeIcon icon='check' className={styles.tick} />
          Receive updates on your favourites
        </li>
        <li className={styles.litem}>
          {' '}
          <FontAwesomeIcon icon='check' className={styles.tick} />
          Benefit from special company discount
        </li>
      </ol>
      <button
        type='submit'
        className={styles.btnsignup}
        onClick={() => navigate('/user/signup')}
      >
        Sign Up <FontAwesomeIcon icon='user-plus' />
      </button>
    </div>
  );
}

export default SignupCandy