exports.orderStatus = (code) => {
  switch (code) {
    case 1:
      return 'confirmed';
    case 2:
        return 'Under verification';
    case 3:
        return 'Cancelled';
    case 4: 
        return 'Under packaging'
    case 5:
        return 'Ready to be shipped'
    case 6:
        return 'Shipped, in transit'
    case 7:
        return 'Delivered'
  }
};
