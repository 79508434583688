import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './../components/listingform.module.css'


function ImageDrop({ handleFileChange, fileSize }) {
  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, maxFiles:6,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg', '.gif'],
    },
  });

  return (
    <div
      {...getRootProps()}
      className={`${styles.filezone} ${fileSize ? styles.hasfiles : undefined}`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className='text-normal'>
          Drag 'n' drop product images here, or click to select files...
        </p>
      ) : (
        <p className='text-normal'>
          Drag 'n' drop product images here, or click to select files
        </p>
      )}
    </div>
  );
}

export default ImageDrop;