import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import FeaturedProduct from './featured_product';

const Banner = () => {
  //set initial states,listings and query filters
  const url = `${process.env.REACT_APP_API}`;
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  //Get all products and equipments to display -------------\\
  useEffect(() => {
    const getProducts = async () => {
      // Get featured products on page load..
      const response = await axios.get(
        `${url}/api/featured`
      );
      setProducts(response.data);
      setLoading(false);

    };
    getProducts();
   
    return () => {
      setLoading(false);
    };
  }, []);

  return isLoading ? (
    // Waiting data loading after async request...
    <div className='loading'>
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    <div className='banner-container'>
      <div
        id='carouselExampleIndicators'
        className='carousel slide carousel-fade'
        data-bs-ride='carousel'
      >
        <div className='carousel-indicators'>
          {products.length > 0 &&
            products.map((photo, i) => {
              return (
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to={i.toString()}
                  className={i === 0 && 'active'}
                  aria-current={i === 0 && 'true'}
                  aria-label={`Slide ${i + 1}`}
                ></button>
              );
            })}
        </div>
        <div className='carousel-inner'>
          {products.length > 0 &&
            products.map((product, index) => {
              return (
                <div
                  className={`carousel-item ${index === 0 && 'active'}`}
                  key={index}
                >
                  <FeaturedProduct
                    className='d-block w-100'
                    product={product}
                  />
                </div>
              );
            })}
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselExampleIndicators'
          data-bs-slide='prev'
        >
          <span
            className='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselExampleIndicators'
          data-bs-slide='next'
        >
          <span
            className='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
    </div>
  );
}

export default Banner
