import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useEffect, useState } from 'react';
import SEO from '../components/seo';
import Item from '../components/checkout/item';
import styles from './delivery.module.css';
import FooterProfile from '../components/footer_profile';
import { useSelector, useDispatch } from 'react-redux';
import countries from '../data/countries.json';
import isLoggedIn from '../utils/isLoggedIn';

const Delivery = () => {
  // Initial state and variables ---------------------------------------------------\\
  const dispatch = useDispatch();
  const url = `${process.env.REACT_APP_API}/api`;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { cart } = useSelector((state) => state);
  const { user, token } = useSelector((state) => state.user);
  const [shipping, setShipping] = useState(null);
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  // const token = sessionStorage.getItem('token');
  const [spinner, setSpinner] = useState(false);
  const [isDeliverable, SetIsDeliverable] = useState(false);
  const navigate = useNavigate();

  //check if user is logged in
  useEffect(() => {
    isLoggedIn(navigate, dispatch, '/delivery');
    setLoading(false);
  }, [token]);

  // Form submit handler ---------------------------------------------------\\
  const validateDelivery = async (data) => {
    if (!spinner) {
      setSpinner(true);
    }

    try {
      //Validate Delivery Address
      const deliverable = await axios.post(
        `${url}/shipping/validateaddress`,
        { ...data, cart },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      // adress is deliverable , calculate delivery charges
      if (deliverable.status === 200) {
        //delivery charges calculation
        // Single cart item, quantity ONE
        if (cart.length === 1 && cart[0].orderedQty <= 1) {
          const shipping = await axios.post(
            `${url}/shipping/rate/${cart[0].id}`,
            data,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );
          if (shipping.status === 200) {
            toast.success(deliverable.data?.message, { duration: 3000 });
            setSpinner(false);
            SetIsDeliverable(true);
            setShipping(shipping.data);
          }
        } else {
          // Multiple items cart
          const shipping = await axios.post(
            `${url}/shipping/rates`,
            { ...data, cart },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          );
          if (shipping.status === 200) {
            toast.success(deliverable.data?.message, { duration: 3000 });
            setSpinner(false);
            SetIsDeliverable(true);
            setShipping(shipping.data);
          }
        }
      }
    } catch (err) {
      toast.error(
        `${err.response.data.message} \nError Code: ${err.response.status}`,
        { duration: 5000 }
      );
      SetIsDeliverable(false);
      setSpinner(false);
    }
  };

  //Calculate total cart value
  const cartTotal = (cart) => {
    if (cart.length) {
      return cart.reduce((cur, acc) => {
        return cur + acc.price * acc.orderedQty;
      }, 0);
    }
    return 0;
  };

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Delivery`}
        page='delivery'
        description={`BADGERS: Delivery`}
        name='BADGERS'
        type='delivery'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <SEO
        title={`BADGERS: Delivery`}
        page='delivery'
        description={`BADGERS: Delivery`}
        name='BADGERS'
        type='delivery'
      />
      <div className='container'>
        <Toaster position='top-center' reverseOrder={false} />
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/cart'>
            Cart{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Delivery</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            Delivery <FontAwesomeIcon icon='truck-moving' />
          </h4>
        </div>
        {/* Delivery Address >>>>>>>>>>>>>> */}
        <div>
          <div className={`${styles.card} row mb-2 sellsecond-zone`}>
            <form className='row g-3' onSubmit={handleSubmit(validateDelivery)}>
              {/* ----------------------------------------------- */}
              <div className='col-12 d-flex justify-content-start my-1'>
                <h4 className='h4'>Delivery Address:</h4>
              </div>
              <div className='my-2 col-md-4'>
                <label htmlFor='country' className='form-label'>
                  Country
                </label>
                <select
                  disabled={isDeliverable}
                  id='type'
                  name='type'
                  className='form-select form-control'
                  {...register('country', {
                    required:
                      'Please select a country from the list, where product is to be delivered.',
                    minLength: 3,
                  })}
                >
                  <option className='default-select' value=''>
                    Select Delivery Location
                  </option>
                  {countries.map((obj, idx) => (
                    <option
                      value={obj.name}
                      key={idx}
                      selected={obj.name == user.userCountry}
                    >
                      {obj.name}
                    </option>
                  ))}
                </select>
                {errors?.country && (
                  <p className='help-block text-danger my-2'>
                    {errors.country?.message}
                  </p>
                )}
              </div>
              <div className='my-2 col-md-4'>
                <label htmlFor='city' className='form-label'>
                  City
                </label>
                <input
                  defaultValue={user.userCity}
                  disabled={isDeliverable}
                  type='text'
                  id='city'
                  name='city'
                  className='form-control'
                  {...register('city', {
                    required: 'Please enter the city where product is located.',
                    minLength: {
                      value: 3,
                      message: 'Please a enter a valid city name.',
                    },
                    maxLength: {
                      value: 50,
                      message: 'City name length exceeds the valid name.',
                    },
                  })}
                />
                {errors?.city && (
                  <p className='help-block text-danger my-2'>
                    {errors.city?.message}
                  </p>
                )}
              </div>
              <div className='my-2 col-md-4'>
                <label htmlFor='postcode' className='form-label'>
                  Post Code
                </label>
                <input
                  disabled={isDeliverable}
                  type='text'
                  id='postcode'
                  name='postcode'
                  className='form-control'
                  {...register('postcode', {
                    minLength: {
                      value: 3,
                      message: 'Please a enter a valid postcode.',
                    },
                    maxLength: {
                      value: 20,
                      message: 'postcode length exceeds the maximum allowed.',
                    },
                  })}
                />
                {errors?.postcode && (
                  <p className='help-block text-danger my-2'>
                    {errors.postcode?.message}
                  </p>
                )}
              </div>
              <div className='my-2 col-md-8'>
                <label htmlFor='street' className='form-label'>
                  Street Name & Number
                </label>
                <input
                  disabled={isDeliverable}
                  type='text'
                  id='street'
                  name='street'
                  className='form-control'
                  {...register('street', {
                    minLength: {
                      value: 5,
                      message: 'Please a enter a valid street number and name.',
                    },
                    maxLength: {
                      value: 200,
                      message: 'Address length exceeds the maximum allowed.',
                    },
                  })}
                />
                {errors?.street && (
                  <p className='help-block text-danger my-2'>
                    {errors.street?.message}
                  </p>
                )}
              </div>

              {!spinner &&
                (!isDeliverable ? (
                  <div className='my-2 col-md-4'>
                    <div className='d-flex gap-2 my-4'>
                      <Link
                        className={`btn btn-danger ${styles.btncancel}`}
                        to={`/cart`}
                      >
                        Go to Cart
                      </Link>
                      <button
                        type='submit'
                        className={`btn ${styles.btnsubmit}`}
                      >
                        Validate Address {'  '}
                        <FontAwesomeIcon icon='check' />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='my-2 col-md-4'>
                    <div className='d-flex gap-2 my-4'>
                      <btn
                        className={`btn btn-danger ${styles.btncancel}`}
                        onClick={() => {
                          SetIsDeliverable(false);
                          setShipping(null);
                        }}
                      >
                        Cancel
                      </btn>
                      <button
                        className={`${styles.btnsubmit} btn`}
                        onClick={() =>
                          navigate(`/checkout?cartid=${shipping?.cartId}`)
                        }
                      >
                        {`Checkout `}
                        <FontAwesomeIcon icon='credit-card' />
                      </button>
                    </div>
                  </div>
                ))}
              {spinner && (
                <div className='my-2 col-md-4'>
                  <div className='d-grid gap-2 my-4'>
                    <button className={`btn ${styles.btnsubmit}`}>
                      Validating delivery address...
                      <div
                        className='spinner-grow spinner-grow-sm'
                        role='status'
                      ></div>
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className={`${styles.card} row mb-2 sellsecond-zone`}>
            {cart.length ? (
              <div className={styles.cart}>
                <div className={styles.carthead}>
                  <div>{`${cart.length} ${
                    cart.length === 1 ? 'Article' : 'Articles'
                  }`}</div>
                </div>
                <div>
                  {cart?.map((product, index) => (
                    <Item product={product} key={`${index}${product.id}`} />
                  ))}
                </div>
                <div className={styles.cartsub}>
                  <div>Sub-Total</div>
                  <div>
                    {cartTotal(cart)?.toFixed(2)}{' '}
                    {cart[0]?.currency === 'eur' ? '€' : 'US$'}
                  </div>
                </div>
                {shipping && (
                  <>
                    <div className={styles.cartvalue}>
                      <div>{`Delivery Charges (DHL ${shipping.productName}, ${shipping.productCode})`}</div>
                      <div>{`${shipping.totalPrice.price.toFixed(2)} ${
                        shipping.totalPrice.priceCurrency.toLowerCase() ===
                        'eur'
                          ? '€'
                          : 'US$'
                      }`}</div>
                    </div>
                    <div className={styles.cartvalue}>
                      <div>Total</div>
                      <div>
                        <strong>
                          {(
                            cartTotal(cart) + shipping.totalPrice.price
                          ).toFixed(2)}{' '}
                          {cart[0].currency === 'eur' ? '€' : 'US$'}
                        </strong>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              'Your shopping cart is empty.'
            )}

            {isDeliverable && (
              <div className='d-grid gap-2 my-3'>
                <button
                  className={`btn ${styles.btnsubmit}`}
                  onClick={() =>
                    navigate(`/checkout?cartid=${shipping?.cartId}`)
                  }
                >
                  {`Checkout `}
                  <FontAwesomeIcon icon='credit-card' />
                </button>
              </div>
            )}
          </div>
        </div>
        {/* Delivery Address <<<<<<<<<<<<<< */}
      </div>
      <FooterProfile />
    </>
  );
};

export default Delivery;
