import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SEO from '../components/seo';
import styles from './industries.module.css';
import Banner from '../components/banner';

const Values = () => {
  return (
    <>
      <Banner />
      <SEO
        title={`BADGERS: Revolutionizing Surplus Materials Management`}
        page='our values'
        description={`Explore BADGERS' innovative platform for surplus materials in energy and industrial sectors. Connect, minimize waste, and maximize cost savings with cutting-edge technology.`}
        keywords={`Inventory marketplace`}
        robots={`index, follow`}
        type='website'
        creator='Badgers SAS'
        link={`https://www.bdgrs.com/about`}
        image='https://www.bdgrs.com/img/about/vision.png'
      />

      <div className='container'>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Our Values</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            About Us <FontAwesomeIcon icon='people-arrows' />
          </h4>
        </div>
        <div className='d-flex justify-content-center'>
          <div class={styles.timeline}>
            <div class={styles.outer}>
              <div class={styles.card}>
                <div class={styles.info}>
                  <div className='d-flex justify-content-center mb-5'>
                    <img
                      src='/img/about/whoweare.png'
                      alt='who_we_are'
                      className={styles.img}
                    />
                  </div>
                  <h3 class={styles.title}>WHO WE ARE</h3>

                  <div className={styles.desc}>
                    <p>
                      Welcome to <strong>BADGERS</strong>, a trailblazer
                      reshaping surplus materials management in the energy and
                      industrial sectors through cutting-edge digital
                      technologies. Our innovative platform is the vital link,
                      seamlessly connecting companies with surplus materials to
                      those actively seeking them. This transformative
                      connection minimizes waste and maximizes cost savings,
                      championing a more efficient and sustainable approach for
                      businesses.
                      <p className='mt-3'>
                        With our long-lasting industry experience,{' '}
                        <strong>BADGERS</strong> stands as a bastion of
                        expertise, dedicated to helping you optimize operations.
                        Partnering with us goes beyond embracing innovation; it
                        ensures improved project efficiency, reduced
                        environmental impact, and enhanced resource utilization
                        in your inventory management.
                      </p>
                    </p>
                  </div>
                  <h3 class={styles.title}>WHY CHOOSE BADGERS?</h3>

                  <div className={styles.desc}>
                    <p>
                      <strong>Innovation at Your Fingertips: </strong>
                      At <strong>BADGERS</strong>, we deliver innovation in
                      surplus materials management through a user-friendly
                      platform, your preferred inventory marketplace. Our
                      commitment to staying at the cutting edge of technology
                      guarantees a seamless and effective experience for your
                      business.
                    </p>
                    <p className='mt-3'>
                      <strong>Operational Optimization: </strong>Our extensive
                      industry knowledge translates into tailored solutions,
                      guiding oil & mining companies through challenges and
                      optimizing their inventory management. This strategic
                      approach ensures your resources are not just used but
                      utilized efficiently.
                    </p>
                    <p className='mt-3'>
                      <strong>Environmental Stewardship: </strong> We are
                      actively contributing to environmental stewardship by
                      connecting surplus materials with those in need. Join our
                      mission to reduce waste and promote a sustainable approach
                      to resource utilization, particularly in the extractive
                      industries.
                    </p>
                    <p className='mt-3'>
                      <strong>Project Efficiency:</strong> Partnering with Us
                      means translating your efforts into enhanced project
                      efficiency. Understanding the unique demands of inventory
                      management for energy companies, our platform ensures your
                      resources are not just managed but maximized.
                    </p>
                  </div>
                  <h3 class={styles.title}>JOIN OUR MISSION</h3>

                  <div className={styles.desc}>
                    <p>
                      Join us in our mission to create a sustainable future for
                      the energy and industrial sectors by transforming the way
                      surplus materials are managed. Choosing{' '}
                      <strong>BADGERS</strong> isn't just about optimizing
                      operations; it's about actively contributing to a more
                      sustainable and efficient future, especially in the
                      context of inventory management for oil and mining
                      companies.
                    </p>
                  </div>
                </div>
              </div>
              <div class={styles.card}>
                <div class={styles.info}>
                  <div className='d-flex justify-content-center mb-5'>
                    <img
                      src='/img/about/vision.png'
                      alt='our_vision'
                      className={styles.img}
                    />
                  </div>
                  <h3 class={styles.title}>OUR VISION</h3>
                  <div className={styles.desc}>
                    <p className='mt-3'>
                      At <strong>BADGERS</strong> our vision extends beyond the
                      present. We aspire to become Africa's largest inventory
                      marketplace for the energy and industrial sectors,
                      establishing ourselves as a key global player for decades
                      to come.
                    </p>
                    <p className='mt-3 mb-2'>By 2030, we aim to:</p>
                    <ul>
                      <li className={styles.bullet}>
                        <strong>Serve 3,000 Users:</strong> BADGERS connects
                        businesses and fosters collaboration, ensuring efficient
                        surplus materials management in the oil and gas industry
                        and the mining sector.
                      </li>
                      <li className={styles.bullet}>
                        <strong>Create 300 Jobs: </strong>Our commitment extends
                        to contributing to economic growth and job creation
                        within the energy and industrial sectors, with a
                        specific focus on inventory management for mining
                        companies.
                      </li>
                      <li className={styles.bullet}>
                        <strong>
                          Generate a 30-Fold Return for Our Investors:
                        </strong>
                        We are dedicated to ensuring mutually beneficial
                        partnerships and sustained growth through our surplus
                        materials marketplace.
                      </li>
                    </ul>
                    <p className='mt-3'>
                      <strong>BADGERS</strong> is not merely a platform; We are
                      a catalyst for positive change in surplus materials
                      management. Choose us to navigate the future of efficient
                      and sustainable inventory management for mining companies.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div class={styles.card}>
                <div class={styles.info}>
                  <h3 class={styles.title}>OUR VALUES</h3>
                  <div className='d-flex justify-content-center mb-5'>
                    <img
                      src='/img/about/about_us.png'
                      alt='about_us'
                      className={styles.img}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;
