import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import convertEntities from '../utils/convertEntities';
import styles from './product.module.css';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import SEO from '../components/seo';
import Counter from '../components/checkout/counter';

const Product = () => {
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/api/product/${id}`;
  const navigate = useNavigate();

  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const [confirmDelete, toggleConfirmDelete] = useState(false); //Loading isloading toggle
  const { user, token } = useSelector((state) => state.user);
  const userId = user.userId;

  // Query to get product data
  useEffect(() => {
    let active = true;
    (async () => {
      const productData = await axios(url);
      if (active) {
        setProduct(productData.data);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setProduct(null);
    };
  }, [id]);



     const deleteListing = async () => {
       const ImagesKeys = product.images?.map((photo) => photo.lienImage);
       axios
         .put(
           `${process.env.REACT_APP_API}/api/listing/delete/${id}`,
           { ImagesKeys },
           {
             headers: { authorization: `Bearer ${token}` },
           }
         )
         .then((response) => {
           if ((response.status = 200)) {
             toast.success(response?.data?.message, {
               duration: 3000,
             });
             setTimeout(() => {
               return navigate('/user/listings');
             }, 3500);
           }
         })
         .catch((err) => {
           toast.error(err.message, {
             duration: 3000,
           });
         });
     };


     //Delete confirmation button toggle handler
     const toggleDelete = () => {
       toggleConfirmDelete((state) => !state);
     };

  // -----------------------------------------------------------------------------

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <div className={`${styles.background} container`}>
      <SEO
        title={`${product.title}`}
        page={product.title}
        description={`${product.categorie}, ${product.condition} ${product.brand} ${product.title}`}
        keywords={`${product.categorie}, ${product.title}`}
        robots={`index, follow`}
        link={`https://www.bdgrs.com/product/${id}`}
        type='website'
        creator='Badgers SAS'
        image='https://www.bdgrs.com/img/logo.png'
      />
      <Toaster position='top-center' reverseOrder={false} />
      {/* ------------Navlinks----------- */}
      <div className=''>
        <Link className='page-links' to='/'>
          <FontAwesomeIcon icon='home' />{' '}
        </Link>
        <span className='link-span'>
          <FontAwesomeIcon icon='angle-right' />
        </span>
        <span className='link-span'>{product.title}</span>
      </div>
      {/* ----------------------- */}

      {/* .....................Product Details.............. */}

      <div className={`${styles.card} row g-4 my-3 pb-3`}>
        {/* -------------Product images carrousel start---------- */}
        <div
          id='carouselExampleIndicators'
          className='col-md-6 carousel slide'
          data-bs-ride='true'
        >
          <div className='carousel-indicators'>
            {product.images.length > 0 &&
              product.images.map((photo, i) => {
                return (
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to={i.toString()}
                    className={i === 0 && 'active'}
                    aria-current={i === 0 && 'true'}
                    aria-label={`Slide ${i + 1}`}
                  ></button>
                );
              })}
          </div>
          <div className='carousel-inner'>
            {product.images.length > 0 &&
              product.images.map((photo, i) => {
                return (
                  <div
                    className={`carousel-item ${i === 0 && 'active'}`}
                    key={i}
                  >
                    <img
                      src={photo?.lienImage}
                      className='d-block w-100'
                      alt={`product-image-${i}`}
                    />
                  </div>
                );
              })}
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>

        {/* ------------Product images carrousel end----------- */}
        <div className='product-block col-md-6'>
          <div className='d-flex justify-content-between'>
            <h4
              className='h4'
              dangerouslySetInnerHTML={{
                __html: product.title,
              }}
            />
          </div>

          <h5 className='h5'>
            {product.currency === 'usd' ? '$ ' : '€ '}
            {Number(product.price).toLocaleString('en')}
          </h5>
          <p className='product-subheads product-type mb-2 mt-3'>
            <span className={styles.label}>Category:</span>
            <span className={styles.value}>{product.categorie}</span>
          </p>
          <p className='product-subheads product-type mb-2'>
            <span className={styles.label}>Type:</span>
            <span className={styles.value}>{product.type}</span>
          </p>
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Brand, Manufacturer:</span>
            <span className={styles.value}>{product.brand}</span>
          </p>
          <p className='product-subheads product-condition mb-2'>
            <span className={styles.label}>Condition:</span>
            <span className={styles.value}>{product.condition}</span>
          </p>
          <p className='product-subheads product-location mb-2'>
            <span className={styles.label}>Region:</span>
            <span className={styles.value}>{product.region}</span>
          </p>
          <p className='product-subheads product-location mb-4'>
            <span className={styles.label}>Available Units:</span>
            <span className={styles.value}>{product.qty}</span>
          </p>
          {userId === product.userId && (
            <>
              <p className='mt-3'>
                {confirmDelete ? (
                  <button
                    className={`${styles.del} ${styles.btn}`}
                    onClick={deleteListing}
                  >
                    {`DELETE ANYWAY??  `}
                    <FontAwesomeIcon icon='trash' />
                  </button>
                ) : (
                  <button
                    className={`${styles.del} ${styles.btn}`}
                    onClick={toggleDelete}
                  >
                    {`DELETE LISTING  `}
                    <FontAwesomeIcon icon='trash' />
                  </button>
                )}
              </p>
              <p className='mt-1'>
                <button
                  className={`${styles.edit} ${styles.btn}`}
                  onClick={() => navigate(`/listing/edit/${product.id}`)}
                >
                  {`EDIT LISTING  `}
                  <FontAwesomeIcon icon='pen-square' />
                </button>
              </p>
            </>
          )}
          {product?.shipping?.length && product?.shipping[0].isShippable ? (
            <Counter product={product} />
          ) : userId !== product.userId ? (
            <p className='product-subheads product-location mt-1'>
              <button
                className={`${styles.quote} ${styles.btn}`}
                onClick={() => navigate(`/quote/${id}`)}
              >
                {`GET A QUOTE  `}
                <FontAwesomeIcon icon='barcode' />
              </button>
            </p>
          ) : undefined}
        </div>
      </div>
      <div className={`${styles.card} row my-3 py-3`}>
        <h5 className='h5 mb-2'>Description:</h5>
        <p className={styles.paragraph}>
          {convertEntities(product.description)}
        </p>
      </div>
    </div>
  );
};

export default Product;
