import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styles from './footer.module.css';


const FooterProfile = () => {
    
  return (
    <div className={styles.footerbody}>
      <div className={styles.logopan}>
        <img className='logo' src='/img/BDGRS_baseline_full.png' alt='' />
        <p>12 avenue des Prés</p>
        <p>78180 Montigny le Bretonneux</p>
        <p>FRANCE</p>
        <div className={styles.mediaicons}>
          <a href='https://www.linkedin.com/company/bdgrs/' target='_blank'>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href='https://www.facebook.com/people/BDGRS-Badgers/100089303595261/'
            target='_blank'
          >
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a href='https://twitter.com/BadgersSas' target='_blank'>
            <FontAwesomeIcon icon={faTwitterSquare} />
          </a>
        </div>
      </div>
      <div className={styles.aboutpan}>
        <p>WHO WE ARE</p>
        <a href='/values'>Our Values</a>
        <a href='/industries'>Industries</a>
        <a href='/services'>Services</a>
      </div>
      <div className={styles.contactpan}>
        <p>CONTACT US</p>
        <p>Tel: +33 9 77196400</p>
        {/* <p>Fax: +33 1 12345678</p> */}
        <p>
          <a href='mailto: info@bdgrs.com'>Email: info@bdgrs.com</a>
        </p>
        <p>
          <a
            target='_blank'
            href='https://mu.ariba.com/profile/public?anId=AN11185344077&sourceApplication=SBN'
          >
            <img src='/img/icons/ariba.jpg' alt='Find us on Ariba Network' />
          </a>
        </p>
      </div>
    </div>
  );
}

export default FooterProfile
