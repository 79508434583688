const Footer = () => {
  return (
    <div className='footer'>
      <p className='foot-notes'>
        <span>© 2023 BADGERS SAS | {'  '}</span>
        <a id='terms-conditions' href='https://www.bdgrs.com/terms' aria-label="terms-conditions" target='blank'>
          Terms & Conditions | {'  '}
        </a>
        <a id='privacy-policy' href='https://www.bdgrs.com/privacy' aria-label="privacy-policy" target='blank'>
          Privacy Policy | {'  '}
        </a>
        <a id='contact-us' href='mailto:info@bdgrs.com' aria-label="contact-us" target='blank'>
          Contact Us
        </a>
      </p>
    </div>
  );
};

export default Footer;
